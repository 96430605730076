<template>
  <div class="reset-pin">
    <!-- <Header /> -->
    <b-container class="reset-pin-container">
      <b-row class="justify-content-md-start back-nav">
        <b-link href="/setting"><b-icon class="mx-2" icon="chevron-left"></b-icon><span>Kembali</span></b-link>
      </b-row>
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1 class="text-center">Ganti PIN</h1>
              <template>
                <div>
                  <form @submit.prevent="handleSubmit">
                    <h3 class="reset-pin-label">PIN Sekarang</h3>
                    <div class="row">
                      <div class="col-9">
                        <vs-input v-show="!showOldPIN" id="old_pin" type="pin" class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="old_pin" required />
                        <vs-input v-show="showOldPIN" id="old_pin" type="text" class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="old_pin" required />
                      </div>
                      <div class="col-3">
                        <b-button @click="showOldPIN = !showOldPIN" block class="btn-show-pin py-2">
                          <div v-show="!showOldPIN">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showOldPIN">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="error my-2" v-if="wrongCurrentPin">
                      <p>pin sekarang tidak sesuai.</p>
                    </div>

                    <h3 class="reset-pin-label">PIN Baru</h3>
                    <div class="my-2">
                      <small><i>*pin harus terdiri dari 4 digit angka</i>
                      </small>
                    </div>
                    <div class="row">
                      <div class="col-9">
                        <vs-input v-show="!showPin" id="pin" type="pin" class="mb-2 mr-sm-2 mb-sm-0" v-model="pin"
                          required @change="inputText" />
                        <vs-input v-show="showPin" id="pin" type="text" class="mb-2 mr-sm-2 mb-sm-0" v-model="pin"
                          required @change="inputText" />
                      </div>
                      <div class="col-3">
                        <b-button @click="showPin = !showPin" block class="btn-show-pin py-2">
                          <div v-show="!showPin">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showPin">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="error my-2" v-if="!$v.pin.minLength">
                      <p>
                        PIN minimal
                        {{ $v.pin.$params.minLength.min }} karakter.
                      </p>
                    </div>
                    <div class="error my-2" v-if="!$v.pin.maxLength">
                      <p>
                        PIN minimal
                        {{ $v.pin.$params.maxLength.max }} karakter.
                      </p>
                    </div>

                    <h3 class="reset-pin-label">Konfirmasi PIN</h3>
                    <div class="row">
                      <div class="col-9">
                        <vs-input v-show="!showConfirmationPin" id="pin_confirmation" type="pin"
                          class="mb-2 mr-sm-2 mb-sm-0" v-model="pin_confirmation" required @change="inputText" />
                        <vs-input v-show="showConfirmationPin" id="pin_confirmation" type="text"
                          class="mb-2 mr-sm-2 mb-sm-0" v-model="pin_confirmation" required @change="inputText" />
                      </div>
                      <div class="col-3">
                        <b-button @click="showConfirmationPin = !showConfirmationPin" block class="btn-show-pin py-2">
                          <div v-show="!showConfirmationPin">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showConfirmationPin">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>

                    <div class="error my-2" v-if="!$v.pin_confirmation.sameAsPin">
                      <p>PIN yang Anda masukkan tidak sama.</p>
                    </div>
                    <div class="error my-2" v-if="newpinNotSame">
                      <p>Konfirmasi PIN tidak cocok.</p>
                    </div>
                    <div class="error my-2" v-if="wrongpinFormat">
                      <p>Format isian PIN tidak valid.</p>
                    </div>
                    <div class="mb-4"></div>

                    <!-- <div class="my-2" v-if="failedChangepin">
                      <p>
                        Email dan PIN tidak sesuai <br />
                        Silahkan cek kembali email dan PIN Anda
                      </p>
                    </div> -->

                    <div class="pt-2">
                      <b-button block :disabled="loading" type="submit" @click="handleSubmit()"
                        class="btn-tertiary py-2">
                        <div v-if="loading">
                          <div class="spinner-border spinner-border-sm"></div>
                          Loading
                        </div>
                        <div v-else>Submit</div>
                      </b-button>
                    </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

      <div class="modal-reset-pin">
        <b-modal id="modal-success-reset-pin" v-model="successChangePin" size="lg" class="modal-success-reset-pin"
          centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/verification-proccess.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">Ganti PIN sukses</h1>
          </div>
        </b-modal>
      </div>

      <div class="modal-reset-pin">
        <b-modal id="modal-success-reset-pin" v-model="failedChangepin" size="lg" class="modal-success-reset-pin"
          centered hide-footer no-close-on-backdrop>
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Terjadi kesahan
            </h1>
            <p class="text-dark">Silahkan hubungi customer care</p>
            <b-row class="justify-content-center">
              <b-button href="https://api.whatsapp.com/send?phone=6287777936468" block
                class="mt-3 mx-2 btn-tertiary py-2">Hubungi customer Care</b-button>
            </b-row>
          </div>
        </b-modal>
      </div>
    </b-container>
    <!-- <Footer4 /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Header from "@/components/partials/header/Header.vue";
// import Footer4 from "@/components/partials/footer/Footer4.vue";
import { sameAs, minLength } from "vuelidate/lib/validators";

import axios from "axios";
import { maxLength } from "vuelidate/lib/validators";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "ChangePin",
  metaInfo: {
    title: "Ganti PIN | CGS iTrade Fund",
  },
  // components: {
  //   Header,
  //   Footer4,
  // },
  data() {
    return {
      successChangePin: false,
      failedChangepin: false,
      newpinNotSame: false,
      pin: "",
      old_pin: "",
      pin_confirmation: "",
      wrongCurrentPin: false,
      wrongpinFormat: false,
      loading: false,
      processing: false,
      showOldPIN: false,
      showPin: false,
      showConfirmationPin: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
      user: "auth/user",
      check: "auth/check",
    }),
    ...mapActions({
      signout: "auth/signout",
    }),
  },
  validations: {
    pin: {
      minLength: minLength(4),
      maxLength: maxLength(4)
    },
    pin_confirmation: {
      sameAsPin: sameAs("pin"),
    },
  },
  methods: {
    inputText() {
      this.wrongpinFormat = false;
    },
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("client_id", (this.user.individual ? this.user.individual : this.user.institutional).client_id);
        data.append("pin", this.pin);
        data.append("pin_confirmation", this.pin_confirmation);
        data.append("old_pin", this.old_pin);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(`${this.app_url}2829`, data, axiosConfig)
          .then(() => {
            this.loading = !false;
            instance.post(`${process.env.VUE_APP_ROOT_API}/logout`);
            window.setTimeout(function () {
              window.location.href = "/";
            }, 3000);
            this.successChangePin = true;
            this.processing = false;
          })
          .catch((err) => {
            if (err.response.data.data.message == "Wrong Current PIN") {
              this.processing = false;
              this.successChangePin = false;
              this.failedChangepin = false;
              this.newpinNotSame = false;
              this.wrongpinFormat = false;
              this.wrongCurrentPin = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Konfirmasi PIN tidak cocok."
            ) {
              this.processing = false;
              this.successChangePin = false;
              this.failedChangepin = false;
              this.wrongCurrentPin = false;
              this.wrongpinFormat = false;
              this.newpinNotSame = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Format isian PIN tidak valid."
            ) {
              this.processing = false;
              this.successChangePin = false;
              this.failedChangepin = false;
              this.wrongCurrentPin = false;
              this.newpinNotSame = false;
              this.wrongpinFormat = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Format isian PIN tidak valid.,Konfirmasi PIN tidak cocok."
            ) {
              this.processing = false;
              this.successChangePin = false;
              this.failedChangepin = false;
              this.wrongCurrentPin = false;
              this.wrongpinFormat = false;
              this.newpinNotSame = err.response.data.data.message;
            } else if (
              err.response.data.data.message == "Isian PIN wajib diisi."
            ) {
              this.processing = false;
              this.successChangePin = false;
              this.failedChangepin = false;
              this.wrongCurrentPin = false;
              this.newpinNotSame = false;
              this.wrongpinFormat = err.response.data.data.message;
            } else {
              this.processing = false;
              this.successChangePin = false;
              this.wrongCurrentPin = false;
              this.newpinNotSame = false;
              this.wrongpinFormat = false;
              this.failedChangepin = err.response.data.data.message;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-pin {
  background: #f3f4f6;
}

.reset-pin-container {
  padding-top: 140px;
  padding-bottom: 140px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #4b5563;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #e53e3e;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #4f566b;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.back-nav {
  padding: 0px 240px 20px 308px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.vs-con-input-label {
  width: 100%;
}

.reset-pin-label {
  padding-top: 16px;
}

.reset-pin-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #6b7280;
  text-decoration: none;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
  .reset-pin-container {
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .reset-pin-container {
    padding-bottom: 60px;
  }

  .back-nav {
    padding: 0px 240px 20px 0px;
  }

  .card-body {
    width: 100%;
  }
}
</style>