<template>
  <b-card class="list-activity-card mt-4">
    <b-row>
      <div class="col-lg-4 col-md-6 my-auto p-0">
        <div class="fund-name">
          <template v-if="value.id_transaction_type === 3">
            <div class="container">
              <img
                :src="
                  imageCore +
                  '/storage/logo/' +
                  value.product_out.investment_manager.logo
                "
              />

              <div>
                <p class="heading">
                  Dari Reksa Dana {{ value.product_out.fund_type }}
                </p>
                <h4>{{ value.product_out.fund_name }}</h4>
              </div>
            </div>
            <div class="container">
              <img
                :src="
                  imageCore +
                  '/storage/logo/' +
                  value.product_in.investment_manager.logo
                "
              />
              <div>
                <p class="heading">
                  Ke Reksa Dana {{ value.product_in.fund_type }}
                </p>
                <h4>{{ value.product_in.fund_name }}</h4>
              </div>
            </div>
          </template>
          <div class="container" v-else>
            <img
              :src="
                imageCore +
                '/storage/logo/' +
                value.product.investment_manager.logo
              "
            />
            <div>
              <p class="heading">Reksa Dana {{ value.product.fund_type }}</p>
              <h4>{{ value.product.fund_name }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 my-auto p-0">
        <div class="investment-value">
          <div>
            <p>Nilai Investasi</p>
            <h6 class="mt-n2">
              IDR
              {{
                formatPrice(
                  value.id_transaction_type === 3
                    ? value.switch_out_nominal
                    : value.amount_nominal
                )
              }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 my-auto p-0">
        <div class="investment-value">
          <div>
            <p>Tanggal Transaksi</p>
            <h6 class="mt-n2">
              {{ formatter(value.updated_at) }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 my-auto p-0">
        <div class="investment-value">
          <div v-if="done">
            <p>{{ moment(value.updated_at).format("DD MMM YYYY") }}</p>
            <h6
              :class="[value.status === 'success' ? 'success' : 'danger']"
              class="status mt-n2"
              v-if="value.id_transaction_type == 1"
            >
              Pembelian {{ value.status === "success" ? "Berhasil" : "Gagal" }}
            </h6>
            <h6
              :class="[value.status === 'success' ? 'success' : 'danger']"
              class="status mt-n2"
              v-else-if="value.id_transaction_type == 2"
            >
              Penjualan {{ value.status === "success" ? "Berhasil" : "Gagal" }}
            </h6>
            <h6
              :class="[value.status === 'success' ? 'success' : 'danger']"
              class="status mt-n2"
              v-else-if="value.id_transaction_type == 3"
            >
              Pengalihan {{ value.status === "success" ? "Berhasil" : "Gagal" }}
            </h6>
          </div>
          <div v-else>
            <p class="success" v-if="value.id_transaction_type == 1">
              Pembelian
            </p>
            <p class="danger" v-else-if="value.id_transaction_type == 2">
              Penjualan
            </p>
            <p class="warning" v-else-if="value.id_transaction_type == 3">
              Pengalihan
            </p>
            <h6 class="status mt-n2" v-if="value.id_transaction_type == 1">
              {{
                value.status === "waiting itrade"
                  ? "Menunggu Verifikasi"
                  : value.status === "waiting confirm"
                  ? "Menunggu Verifikasi"
                  : value.status == "waiting sales"
                  ? "Menunggu Verifikasi Sales"
                  : value.status == "approve"
                  ? "Dalam Proses"
                  : "Menunggu Pembayaran"
              }}
            </h6>
            <h6 class="status mt-n2" v-else-if="value.id_transaction_type == 2">
              {{
                value.status === "waiting itrade"
                  ? "Menunggu Verifikasi"
                  : value.status === "waiting confirm"
                  ? "Menunggu Verifikasi"
                  : value.status == "waiting sales"
                  ? "Menunggu Verifikasi Sales"
                  : value.status == "approve"
                  ? "Dalam Proses"
                  : "null"
              }}
            </h6>
            <h6 class="status mt-n2" v-else-if="value.id_transaction_type == 3">
              {{
                value.status === "waiting itrade"
                  ? "Menunggu Verifikasi"
                  : value.status === "waiting confirm"
                  ? "Menunggu Verifikasi"
                  : value.status == "waiting sales"
                  ? "Menunggu Verifikasi Sales"
                  : value.status == "approve"
                  ? "Dalam Proses"
                  : "null"
              }}
            </h6>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 text-right my-auto">
        <div
          class="status-message"
          :class="{
            success: value.id_transaction_type === 1,
            danger: value.id_transaction_type === 2,
            warning: value.id_transaction_type === 3,
          }"
          v-if="done"
        >
          <p v-if="value.id_transaction_type == 1">Pembelian</p>
          <p v-else-if="value.id_transaction_type == 2">Penjualan</p>
          <p v-else-if="value.id_transaction_type == 3">Pengalihan</p>
        </div>
        <div v-else class="timer-buy">
          <b-row class="justify-content-center justify-content-lg-end">
            <div
              v-if="
                value.id_transaction_type === 1 &&
                value.status == 'waiting transfer'
              "
            >
              <div class="col-lg-12 col-md-6 my-auto text-left text-lg-right">
                <b-button
                  class="btn-buy py-2 px-4"
                  :to="`/order/subscription-checkout/${value.code}/${value.fund_code}`"
                >
                  Konfirmasi
                </b-button>
              </div>
            </div>
            <div
              v-else-if="
                value.status == 'approve' || value.status == 'waiting confirm'
              "
            >
              <!-- <div class="col-lg-12 col-md-6 my-auto text-left text-lg-right">
                <div class="estimation text-right">
                  <p class="ml-0">Estimasi Selesai</p>
                  <h6 class="mt-n2 text-right">
                    {{ nextDayBussiness(value?.updated_at || new Date()) }}
                  </h6>
                </div>
              </div> -->
            </div>
          </b-row>
        </div>
      </div>
    </b-row>
  </b-card>
</template>
<script>
import moment from "moment-business-days";
export default {
  name: "itemTransactionReksadana",
  props: {
    value: Object,
    done: Boolean,
  },
  data() {
    return {
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  methods: {
    nextDayBussiness(date) {
      return moment(date).businessAdd("2", "days").format("DD MMM YYYY");
    },
    formatter(val) {
      return moment(val).format("DD MMM YYYY");
    },
  },
};
</script>

<style scoped lang="scss">
.list-activity-card {
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.15);
  border-radius: 6px;

  .fund-name {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .container {
        display: flex;
        align-items: flex-end;
        gap: 12px;
      }

      img {
        border-radius: 6px;
        width: 40px;
        flex-shrink: 0;
      }

      h4 {
        font-weight: 700;
        font-size: 16px;
        color: #0b318f;
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: #6b7280;
        margin: 0;
      }
    }

    .investment-value {
      .status.black {
        color: black !important;
      }

      .status {
        color: #ee503f;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }

      p,
      h3 {
        &.success {
          color: #2f855a;
          font-weight: bold;
        }

        &.danger {
          color: #c53030;
          font-weight: bold;
        }

        &.warning {
          color: #c05621;
          font-weight: bold;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #6b7280;
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #111827;
      }
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      color: #111827;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #4b5563;
    }

    .estimation {
      p {
        color: #6b7280 !important;
        font-family: Inter !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 150% !important;
      }

      h3 {
        color: #111827;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
      }
    }

    .timer-buy {
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #e60012;
      }
    }

    .status-message {
      padding: 6px 12px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      margin: 0 0 0 auto;

      p {
        margin: 0;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &.success {
        background: #c6f6d5;

        p {
          color: #2f855a;
        }
      }

      &.danger {
        background: #fed7d7;

        p {
          color: #c53030;
        }
      }

      &.warning {
        background: #feebc8;

        p {
          color: #c05621;
        }
      }
    }

    .btn-buy {
      font-family: $inter;
      background: #ff3f4e;
      border-radius: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      border: none;
      color: white;
    }

    .btn-buy:hover {
      background: $hoverSecondary;
    }

    .btn-buy:focus {
      box-shadow: none !important;
    }

    .btn-buy:active {
      background: $hoverSecondary !important;
      outline: none !important;
    }

    @media only screen and (max-width: 768px) {
      h4 {
        font-size: 14px !important;
      }

      h3 {
        font-size: 14px !important;
      }

      p {
        font-size: 14px !important;
      }
    }
  }
}
</style>
