<template>
  <div class="redeem">
    <Header />
    <b-container class="redeem-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link :to="'/redeem/' + this.fund_code"><b-icon class="mx-2"
              icon="chevron-left"></b-icon><span>Kembali</span></b-link>
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="redeem-order p-4">
          <b-form>
            <div class="redeem-title">
              <b-row class="justify-content-center my-2">
                <h4>Penjualan Reksa Dana</h4>
              </b-row>
            </div>
            <div class="fund-name">
              <b-row>
                <div class="col-2 col-lg-1 text-left mb-2">
                  <img :src="imageCore + '/storage/logo/' + portfolio.product.investment_manager.logo" />
                </div>
                <div class="col-9 text-left px-4">
                  <h4>
                    {{ portfolio.product.fund_name }}
                  </h4>
                  <p>
                    {{ portfolio.product.investment_manager.im_name }}
                  </p>
                </div>
              </b-row>
            </div>

            <div class="unit">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Unit</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">{{ formatNab(sell_unit) }} unit</p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="nav-unit">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">
                    NAV/unit per
                    {{ moment(porto_detail.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ porto_detail.fund_ccy
                    }}{{ formatNab(porto_detail.nav_per_unit) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="redeem-value">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Estimasi Penjualan Kembali</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ porto_detail.fund_ccy }}{{ formatPrice(sell_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />

            <div class="redeem-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Biaya Penjualan Kembali</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="variant">
                    {{
                      portfolio.product.fee_sell_text
                        ? portfolio.product.fee_sell_text
                        : "-"
                    }}
                  </p>
                  <!-- <p v-if="user.individual.sales_id != null" class="variant">
                    {{
                      portfolio.product.fee_sell_text
                        ? portfolio.product.fee_sell_text
                        : "-"
                    }}
                  </p>
                  <p v-else class="value">Gratis</p> -->
                </div>
              </b-row>
            </div>
            <hr />
            <div class="total-purchase">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ porto_detail.fund_ccy }}{{ formatPrice(sell_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <!-- <div class="custodian-bank">
              <b-row class="mt-2">
                <b-col>
                  <p class="variant">Bank Kustodian</p>
                </b-col>
                <b-col class="my-auto">
                  <p class="custodian-bank-name">
                    {{
                      product.rekening_product
                        ? product.rekening_product.account_name
                        : ""
                    }}
                  </p>

                </b-col>
              </b-row>
            </div> -->

            <div class="bank-info mt-4">
              <div class="bank-title">
                <b-row class="justify-content-md-start ml-1 mb-4">
                  <div class="bank-box">
                    <b-row>
                      <b-col>
                        <div class="bank-information">
                          <b-row>
                            <h2 class="ml-3">Rekening Bank Anda</h2>
                          </b-row>
                          <b-row>
                            <p class="ml-3">
                              Uang hasil penjualan akan ditransfer oleh Bank
                              Kustodian.
                            </p>
                          </b-row>
                        </div>
                        <template>
                          <div class="dropbox">
                            <b-row>
                              <div class="col-lg-3 col-md-6">
                                <div class="bank-image text-center mx-4 mt-2">
                                  <div v-if="user.individual != null">
                                    <div v-if="accounts?.banks?.logo != null">
                                      <img :src="`${imageCore}/${accounts?.banks?.logo}`" max-height="26" max-width="86"
                                        alt="Bank Image" />
                                    </div>
                                    <div v-else>
                                      <img :src="'https://ui-avatars.com/api/?background=EB321E&color=fff&name=' +
                                        accounts?.banks?.bank_name
                                        " max-height="26" max-width="86" alt="Bank Image" />
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div v-if="
                                      accounts?.banks?.logo !=
                                      null
                                    ">
                                      <img :src="`${imageCore}/${accounts?.banks?.logo}`" max-height="26" max-width="86"
                                        alt="Bank Image" />
                                    </div>
                                    <div v-else>
                                      <img :src="'https://ui-avatars.com/api/?background=EB321E&color=fff&name=' +
                                        accounts?.banks?.bank_name
                                        " max-height="26" max-width="86" alt="Bank Image" />
                                    </div>
                                  </div>
                                  <p class="mt-1">
                                    {{
                                      accounts?.banks?.bank_name ||
                                      accounts?.banks?.bank_name
                                    }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-9 col-md-6 text-center">
                                <div class="payment-account mt-1">
                                  <p>
                                    {{
                                      accounts?.account_number
                                    }}
                                  </p>
                                  <h3>
                                    {{
                                      accounts?.account_name
                                    }}
                                  </h3>
                                </div>
                              </div>
                            </b-row>
                          </div>
                        </template>
                      </b-col>
                    </b-row>
                  </div>
                </b-row>
              </div>
            </div>

            <div class="price-terms mt-4">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <p>
                    <img src="@/assets/img/icons/product/transaction-rules.svg" alt="Ketentuan Transaksi"
                      class="mx-2" />Ketentuan
                    Transaksi
                  </p>
                </div>
              </b-row>
            </div>
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Transaksi sebelum 13:00 WIB</p>
              </b-row>
              <b-row>
                <p class="before-detail text-justify">
                  Batas waktu konfirmasi Penjualan Reksa Dana paling lambat
                  diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul
                  13:00 WIB untuk diproses menggunakan harga NAV/Unit di hari
                  bursa yang sama.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-after ml-3">
              <b-row class="mt-2">
                <p class="after">Transaksi setelah 13:00 WIB</p>
              </b-row>
              <b-row>
                <p class="after-detail text-justify">
                  Transaksi Penjualan yang diterima dan tercatat melebihi batas
                  waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                  NAV/Unit hari bursa berikutnya.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Biaya dan Perpajakan</p>
              </b-row>
              <b-row>
                <p class="before-detail">
                  Biaya dan Perpajakan akan timbul atas dasar ketentuan
                  peraturan perundangan-undangan yang berlaku, sesuai dengan
                  transaksi yang dimiliki.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="agreement-checkbox">
              <b-row>
                <div class="custom-control custom-checkbox mb-4">
                  <input id="agree" type="checkbox" value="accepted" class="custom-control-input mr-2"
                    v-model="checked" />
                  <label for="agree" class="custom-control-label text-justify">
                    Saya telah membaca, memahami, dan menyetujui seluruh isi
                    <b-link :href="'${imageCore}/prospectus/download/' +
                      portfolio.product.prospectus
                      " class="prospectus-link text-danger text-decoration-none" target="_blank">Prospektus</b-link>
                    dan memahami risiko investasi yang saya buat.
                  </label>
                </div>

                <b-button :disabled="!checked || loading" @click="redeemButton()" color="tertiary" block
                  class="btn-tertiary py-2">
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                    Loading
                  </div>
                  <div v-else>Jual Sekarang</div>
                </b-button>
              </b-row>
            </div>
          </b-form>
        </b-card>

        <div class="modal-success-redeem">
          <b-modal v-model="show" size="md" class="modal-failed-register" centered hide-footer hide-header
            no-close-on-backdrop>
            <div class="d-block text-center">
              <img src="@/assets/img/icons/transactions/checked-blue.svg" alt="" />
              <h1 class="header-modal p-4 my-2">
                Penjualan Anda akan segera diproses
              </h1>
              <p>Sekarang Anda tinggal menunggu verifikasi.</p>
              <b-row class="justify-content-center">
                <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2">Aktivitas Dalam Proses</b-button>
              </b-row>
            </div>
          </b-modal>
          <b-modal id="modal-failed-redeem" v-model="failedRedeem" size="md" class="modal-failed-redeem" centered
            hide-footer hide-header no-close-on-backdrop>
            <div class="d-block text-center">
              <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
              <h1 class="header-modal p-4 my-2 text-dark">
                Unit tidak memenuhi minimal penjualan produk
              </h1>
              <b-row class="justify-content-center">
                <b-button :to="'/redeem/' + this.fund_code" block class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
              </b-row>
            </div>
          </b-modal>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import axios from "axios";
import { mapGetters } from "vuex";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "RedeemReview",
  metaInfo: {
    title: "Tinjauan Penjualan | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    if (localStorage.sell_nominal)
      this.sell_nominal = localStorage.sell_nominal;
    if (localStorage.sell_unit) this.sell_unit = localStorage.sell_unit;
    if (localStorage.sell_all_unit)
      this.sell_all_unit = localStorage.sell_all_unit;
    if (this.sell_unit <= 0) {
      this.failedRedeem = true;
    }
  },
  data() {
    return {
      imageCore: process.env.VUE_APP_IMAGE_URL,
      show: false,
      checked: false,
      portfolio: {
        product: {
          fund_name: "",
          prospectus: "",
          investment_manager: {
            im_name: "",
          },
          nav: {
            nav_date: "",
          },
        },
      },
      sell_nominal: 0,
      sell_unit: 0,
      sell_all_unit: "",
      porto_detail: {},
      accounts: {},
      loading: false,
      processing: false,
      failedRedeem: false,
    };
  },

  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
    }),
  },
  created() {
    this.fund_code = this.$route.params.fund_code;
    this.fetchPortfolio();
    this.fetchFund();
    this.fetchDefaultAccount();
  },
  methods: {
    async fetchFund() {
      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.product = response.data.data;
    },
    async fetchDefaultAccount() {
      let response;
      if (this.user.institutional) {
        response = await instance({
          method: "GET",
          url: `${this.app_url}institutional-account/detail-account`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        })
      } else {
        response = await instance({
          method: "GET",
          url: `${this.app_url}individual-account/detail-account`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
      }
      this.accounts = response.data.data;
    },
    async fetchPortfolio() {
      // if (this.user.institutional == null) {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}user-portfolio-product?type=${this.user.institutional == null
          ? 'Individual'
          : "Institutional"}&id=${this.user.institutional == null
            ? this.user.individual.id
            : this.user.institutional.id
          }&fund_code=${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      this.portfolio = response.data.data.userPortfolio[0];
      this.porto_detail = response.data.data.portoDetail;
      console.log(this.portfolio);
      // } else {
      //   let response = await instance({
      //     method: "GET",
      //     url: `${this.app_url}user-portfolio-product?type=Institutional&id=${this.user.institutional.id}&fund_code=${this.fund_code}`,
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //       Authorization: `${this.access_token}`,
      //     },
      //   });
      //   this.portfolio = response.data.data.userPortfolio;
      //   this.porto_detail = response.data.data.portoDetail;
      // }
    },

    redeemButton() {
      if (!this.processing) {
        this.processing = true;
        var postData = {
          ifua_number:
            this.user.individual != null
              ? this.user.individual.ifua
              : this.user.institutional.ifua,
          fund_code: this.fund_code,
          amount_unit: parseFloat(this.sell_unit.replace(/,/g, "")),
          amount_nominal: parseFloat(this.sell_nominal),
          // fee: 1 // Modif 06 Feb 2023,
          fee: this.product.fee_sell,
          fee_type: "PERCENT", // "NOMINAL",
          sell_all_unit: this.sell_all_unit,
          account_id: this.accounts?.id,
          bank_account: this.accounts?.banks?.id,
          individual_id:
            this.user.individual != null ? this.user.individual.id : null,
          institutional_id:
            this.user.institutional != null ? this.user.institutional.id : null,
          nav_per_unit: this.porto_detail.nav_per_unit,
          product_id: this.portfolio.product.id,
          nav_date: this.porto_detail.nav_date,
          is_check: localStorage.is_check,
        };

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        };

        instance
          .post(`${this.app_url}redeem-post`, postData, axiosConfig)
          .then((res) => {
            if (res.status == 200) {
              localStorage.removeItem("sell_nominal");
              localStorage.removeItem("sell_unit");
              localStorage.removeItem("is_check");

              this.show = true;
              this.loading = !false;
            }
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.redeem {
  background: #f3f4f6;
}

.redeem-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.redeem-review {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 260px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
    text-decoration: none;
  }
}

.redeem-order {
  width: 600px;
}

.redeem-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.estimate-accepted {
  .estimate-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
    margin-bottom: 2px;
  }

  .estimate-date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.nav-unit {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.redeem-value {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.redeem-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
    margin-bottom: 4px;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }

  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }

  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #374151;
  }
}

.bank-information {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}

.custom-checkbox:focus {
  box-shadow: none !important;
}

.dropbox {
  outline: 1px solid #d1d5db;
  background: white;
  color: dimgray;
  padding: 10px 10px;
  height: 100px;
  /* minimum height */
  width: 500px;
  /* minimum height */
  position: relative;
  border-radius: 5px;
}

.bank-image {
  img {
    width: 40px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #4b5563;
    margin-bottom: 0px;
  }
}

.payment-account {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
    margin-bottom: 0px;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.btn-tertiary {
  font-family: $inter;
  background: $tertiaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  color: #29064c;
  border: none;
}

.btn-tertiary:hover {
  background: $hoverTertiary;
}

.btn-tertiary:focus {
  box-shadow: none !important;
}

.btn-tertiary:active {
  background: $hoverTertiary !important;
  outline: none !important;
  color: #29064c !important;
}

.header-modal {
  font-size: 32px;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }

  .dropbox {
    height: 200px;
    /* minimum height */
    width: 100%;
    /* minimum height */
  }

  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }

  p {
    font-size: 14px !important;
  }

  label {
    font-size: 14px !important;
  }

  .header-modal {
    font-size: 16px !important;
  }
}
</style>
