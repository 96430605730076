<template>
  <div class="activity">
    <Header />
    <HeaderBottomOrders />
    <div class="investment-activity">
      <b-container class="activity-container">
        <div class="activity-title mb-4">
          <b-row class="justify-content-center">
            <h1>Aktivitas</h1>
          </b-row>
        </div>
        <div>
          <div class="activity-tab">
            <b-row class="justify-content-center">
              <div class="on-proccess mx-4">
                <b-col>
                  <h3><b-link to="/orders">Dalam Proses</b-link></h3>
                </b-col>
              </div>
              <div class="finish mx-4">
                <b-col>
                  <h3><b-link to="#">Selesai</b-link></h3>
                </b-col>
              </div>
            </b-row>
          </div>
        </div>
      </b-container>
    </div>
    <div class="list-activity">
      <b-container class="list-activity-container">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
            <b-row class="my-4 p-4">
              <div class="col-lg-3 col-md-6">
                <b-row>
                  <div class="col-lg-2 col-md-6 mb-4">
                    <b-skeleton type="avatar"></b-skeleton>
                  </div>
                  <b-col>
                    <b-skeleton width="80%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-3 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
              </div>
              <div class="col-lg-2 col-md-6">
                <b-skeleton width="80%"></b-skeleton>
              </div>
            </b-row>
          </template>

          <template v-if="orderHistories.length > 0 || obligasi.length > 0">
            <ButtonListActivity @change-order="changeOrder" :orderBy="orderBy" :activeTab="activeTab"
              @change-active-tab="changeActiveTab" @change-done-filter="changeFilterDoneOrFailed"
              :filterDoneOrFailed="true" :filterDoneOrFailedValue="filterDoneOrFailedValue" />

            <!-- Logic -->
            <ItemReksadana :done="true" :value="value" v-for="(value, index) in filterData.reksadana" :key="index">
            </ItemReksadana>
            <ItemObligasi :done="true" :value="value" v-for="(value, index) in filterData.obligasi"
              :key="index + orderHistories.length"></ItemObligasi>
          </template>
          <!-- <template v-if="user.individual != null">
            <b-card class="list-activity-card mt-4" v-for="(value, index) in ordersLoaded" :key="index">
              <div class="row my-auto">
                <div class="col-lg-6">
                  <p class="transaction-date">
                    {{ moment(value.updated_at).format(" DD MMM YYYY") }}
                  </p>
                </div>
                <div class="col-lg-6">
                  <div v-if="value.id_transaction_type === 1">
                    <div class="row">
                      <template v-if="value.promo_transaction != null">
                        <div class="col-lg-3">
                          <p class="transaction-type text-center">Pembelian</p>
                        </div>

                        <div class="col-lg-3">
                          <p class="transaction-promo text-center">
                            {{ value.promo_transaction.promo_code }}
                          </p>
                        </div>

                        <div class="col-lg-6">
                          <template v-if="value.status === 'success'">
                            <p class="transaction-status-success text-center">
                              {{
                              value.status === "success"
                              ? "Berhasil"
                              : "Gagal"
                              }}
                            </p>
                          </template>
                          <template v-else>
                            <p class="transaction-status-failed text-center">
                              {{
                              value.status === "success"
                              ? "Berhasil"
                              : "Gagal"
                              }}
                            </p>
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-lg-6">
                          <p class="transaction-type text-center text-lg-right">
                            Pembelian
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <template v-if="value.status === 'success'">
                            <p class="transaction-status-success text-center">
                              {{
                              value.status === "success"
                              ? "Berhasil"
                              : "Gagal"
                              }}
                            </p>
                          </template>
                          <template v-else>
                            <p class="transaction-status-failed text-center">
                              {{
                              value.status === "success"
                              ? "Berhasil"
                              : "Gagal"
                              }}
                            </p>
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div v-else-if="value.id_transaction_type === 2">
                    <div class="row">
                      <div class="col-lg-6">
                        <p class="transaction-type text-center text-lg-right">
                          Penjualan
                        </p>
                      </div>
                      <div class="col-lg-6">
                        <template v-if="value.status === 'success'">
                          <p class="transaction-status-success text-center">
                            {{
                            value.status === "success" ? "Berhasil" : "Gagal"
                            }}
                          </p>
                        </template>
                        <template v-else>
                          <p class="transaction-status-failed text-center">
                            {{
                            value.status === "success" ? "Berhasil" : "Gagal"
                            }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-lg-6">
                        <p class="transaction-type text-center text-lg-right">
                          Pengalihan
                        </p>
                      </div>
                      <div class="col-lg-6">
                        <template v-if="value.status === 'success'">
                          <p class="transaction-status-success text-center">
                            {{
                            value.status === "success" ? "Berhasil" : "Gagal"
                            }}
                          </p>
                        </template>
                        <template v-else>
                          <p class="transaction-status-failed text-center">
                            {{
                            value.status === "success" ? "Berhasil" : "Gagal"
                            }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-row>
                <div class="col-lg-4 col-md-6 my-auto">
                  <div class="fund-name">
                    <b-row>
                      <div class="col-lg-12 col-md-6">
                        <div v-if="value.id_transaction_type == 1" class="my-auto">
                          <div class="row">
                            <div class="col-lg-12 col-md-6">
                              <div class="row">
                                <div class="col-lg-2 col-md-6 mb-2">
                                  <div v-if="true">
                                    <img :src="
                                        'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                        value.product.fund_name
                                      " />
                                  </div>
                                  <div v-else>
                                    <img :src="`${imageCore}/images/products/${value.product.image}`" alt="Fund Image" />
                                  </div>
                                </div>
                                <div class="col-lg-10 col-md-6">
                                  <b-button class="btn-modal-portfolio" :to="
                                      '/order/subscription-history/' +
                                      user.individual.ifua +
                                      '/' +
                                      value.id +
                                      '/' +
                                      value.id_transaction_type
                                    ">
                                    <h4 class="text-left mb-2">
                                      {{
                                      value.product
                                      ? value.product.fund_name
                                      : ""
                                      }}
                                    </h4>
                                    <p class="text-left">
                                      Reksa Dana
                                      {{
                                      value.product
                                      ? value.product.fund_type
                                      : ""
                                      }}
                                    </p>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="value.id_transaction_type == 2" class="my-auto">
                          <div class="row">
                            <div class="col-lg-12 col-md-6">
                              <div class="row">
                                <div class="col-lg-2 col-md-6 mb-2">
                                  <div v-if="value.product.image == null && false">
                                    <img :src="
                                        'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                        value.product.fund_name
                                      " />
                                  </div>
                                  <div v-else>
                                    <img :src="`${imageCore}/images/products/${value.product.image}`" alt="Fund Image" />
                                  </div>
                                </div>
                                <div class="col-lg-10 col-md-6">
                                  <b-button class="btn-modal-portfolio" :to="
                                      '/order/redeem-history/' +
                                      user.individual.ifua +
                                      '/' +
                                      value.id +
                                      '/' +
                                      value.id_transaction_type
                                    ">
                                    <h4 class="text-left mb-2">
                                      {{
                                      value.product
                                      ? value.product.fund_name
                                      : ""
                                      }}
                                    </h4>
                                    <p class="text-left">
                                      Reksa Dana
                                      {{
                                      value.product
                                      ? value.product.fund_type
                                      : ""
                                      }}
                                    </p>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="col-lg-12 col-md-6">
                              <div class="row">
                                <div class="col-lg-2 col-md-6 mb-2">
                                  <div v-if="value.product_out.image == null && false">
                                    <img :src="
                                        'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                        value.product_out.fund_name
                                      " />
                                  </div>
                                  <div v-else>
                                    <img :src="`${imageCore}/${value.product_out.image}`" alt="Fund Image" />
                                  </div>
                                </div>
                                <div class="col-lg-10 col-md-6">
                                  <div class="switch-from">
                                    <p class="mb-2">
                                      Dari Reksa Dana
                                      {{
                                      value.product_out
                                      ? value.product_out.fund_type
                                      : ""
                                      }}
                                    </p>
                                  </div>
                                  <b-button class="btn-modal-portfolio" :to="
                                      '/order/switching-history/' +
                                      user.individual.ifua +
                                      '/' +
                                      value.id +
                                      '/' +
                                      value.id_transaction_type
                                    ">
                                    <h4 class="text-left">
                                      {{
                                      value.product_out
                                      ? value.product_out.fund_name
                                      : ""
                                      }}
                                    </h4>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-6">
                              <div class="row">
                                <div class="col-lg-2 col-md-6 mb-2">
                                  <div v-if="value.product_in.image == null">
                                    <img :src="
                                        'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                        value.product_in.fund_name
                                      " />
                                  </div>
                                  <div v-else>
                                    <img :src="`${imageCore}/${value.product_in.image}`" alt="Fund Image" />
                                  </div>
                                </div>
                                <div class="col-lg-10 col-md-6">
                                  <div class="switch-to">
                                    <p class="mb-2">
                                      Ke Reksa Dana
                                      {{
                                      value.product_in
                                      ? value.product_in.fund_type
                                      : ""
                                      }}
                                    </p>
                                  </div>
                                  <b-button class="btn-modal-portfolio" :to="
                                      '/order/switching-history/' +
                                      user.individual.ifua +
                                      '/' +
                                      value.id +
                                      '/' +
                                      value.id_transaction_type
                                    ">
                                    <h4 class="text-left">
                                      {{
                                      value.product_in
                                      ? value.product_in.fund_name
                                      : ""
                                      }}
                                    </h4>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-row>
                  </div>
                </div>
                <div class="col-lg-8 col-md-6 my-auto text-lg-right text-center">
                  <div class="investment-value">
                    <div v-if="value.id_transaction_type == 1">
                      <p>{{ value.amount_unit }} unit</p>
                      <h3 class="mt-n3">
                        {{ value.product ? value.product.fund_ccy : ""
                        }}{{ formatPrice(value.amount_nominal) }}
                      </h3>
                    </div>
                    <div v-else-if="value.id_transaction_type == 2">
                      <p>{{ value.amount_unit }} unit</p>
                      <h3 class="mt-n3">
                        {{ value.product ? value.product.fund_ccy : ""
                        }}{{ formatPrice(value.amount_nominal) }}
                      </h3>
                    </div>
                    <div v-else-if="value.id_transaction_type == 3">
                      <p>{{ value.switch_out_unit }} unit</p>
                      <h3 class="mt-n3">
                        {{ value.product_out ? value.product_out.fund_ccy : ""
                        }}{{ formatPrice(value.switch_out_nominal) }}
                      </h3>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-card>
            </template>
            <template v-else-if="user.institutional != null">
              <b-card class="list-activity-card mt-4" v-for="(value, index) in institutionalOrdersLoaded" :key="index">
                <b-row class="mt-3 mx-1">
                  <div class="col-lg-3 col-md-6">
                    <div class="fund-name">
                      <b-row>
                        <div class="col-lg-2 col-md-6">
                          <div v-if="value.transaction_type == 'Subscription'">
                            <img :src="
                              'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.product.fund_name
                            " />
                          </div>
                          <div v-else-if="value.transaction_type == 'Redemption'">
                            <img :src="
                              'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.product.fund_name
                            " />
                          </div>
                          <div v-else-if="value.transaction_type == 'Switch-In'">
                            <img :src="
                              'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.product.fund_name
                            " />
                          </div>
                          <div v-else-if="value.transaction_type == 'Reinvestment'">
                            <img :src="
                              'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.product.fund_name
                            " />
                          </div>
                          <div v-else>
                            <img :src="
                              'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.product.fund_name
                            " />
                          </div>
                        </div>

                        <b-col>
                          <div v-if="value.transaction_type == 'Subscription'">
                            <div class="btn-modal-portfolio mx-2">
                              <h4>
                                {{ value.product ? value.product.fund_name : "" }}
                              </h4>
                            </div>
                          </div>
                          <div v-else-if="value.transaction_type == 'Redemption'">
                            <div class="btn-modal-portfolio mx-2">
                              <h4>
                                {{ value.product ? value.product.fund_name : "" }}
                              </h4>
                            </div>
                          </div>
                          <div v-else-if="value.transaction_type == 'Switch-In'">
                            <div class="btn-modal-portfolio mx-2">
                              <h4>
                                {{ value.product ? value.product.fund_name : "" }}
                              </h4>
                            </div>
                          </div>
                          <div v-else-if="value.transaction_type == 'Reinvestment'">
                            <div class="btn-modal-portfolio mx-2">
                              <h4>
                                {{ value.product ? value.product.fund_name : "" }}
                              </h4>
                            </div>
                          </div>
                          <div v-else>
                            <div class="btn-modal-portfolio mx-2">
                              <h4>
                                {{ value.product ? value.product.fund_name : "" }}
                              </h4>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 my-auto">
                    <div class="investment-value">
                      <div v-if="value.transaction_type == 'Subscription'">
                        <p>Nilai Investasi</p>
                        <h3 class="mt-n3">
                          {{ value.product ? value.product.fund_ccy : ""
                          }}{{ formatPrice(value.net_transaction_amount) }}
                        </h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Redemption'">
                        <p>Nilai Penjualan</p>
                        <h3 class="mt-n3">
                          {{ value.product ? value.product.fund_ccy : ""
                          }}{{ formatPrice(value.net_transaction_amount) }}
                        </h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Switch-In'">
                        <p>Nilai Pengalihan</p>
                        <h3 class="mt-n3">
                          {{ value.product_out ? value.product_out.fund_ccy : ""
                          }}{{ formatPrice(value.net_transaction_amount) }}
                        </h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Reinvestment'">
                        <p>Nilai Investasi</p>
                        <h3 class="mt-n3">
                          {{ value.product ? value.product.fund_ccy : ""
                          }}{{ formatPrice(value.net_transaction_amount) }}
                        </h3>
                      </div>
                      <div v-else>
                        <p>Nilai Pengalihan</p>
                        <h3 class="mt-n3">
                          {{ value.product_out ? value.product_out.fund_ccy : ""
                          }}{{ formatPrice(value.net_transaction_amount) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 my-auto">
                    <div class="purchased">
                      <div v-if="value.transaction_type == 'Subscription'">
                        <p>
                          {{
                          moment(value.transaction_date).format(" DD MMM YYYY")
                          }}
                        </p>
                        <h3 class="mt-n3">
                          {{
                          value.transaction_type === "Subscription"
                          ? "Pembelian Berhasil"
                          : "Pembelian Berhasil"
                          }}
                        </h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Redemption'">
                        <p>
                          {{
                          moment(value.transaction_date).format(" DD MMM YYYY")
                          }}
                        </p>
                        <h3 class="mt-n3">
                          {{
                          value.transaction_type === "Redemption"
                          ? "Penjualan Berhasil"
                          : "Penjualan Berhasil"
                          }}
                        </h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Switch-In'">
                        <p>
                          {{
                          moment(value.transaction_date).format(" DD MMM YYYY")
                          }}
                        </p>
                        <h3 class="mt-n3">Pengalihan Berhasil</h3>
                      </div>
                      <div v-else-if="value.transaction_type == 'Reinvestment'">
                        <p>
                          {{
                          moment(value.transaction_date).format(" DD MMM YYYY")
                          }}
                        </p>
                        <h3 class="mt-n3">Reinvestment Berhasil</h3>
                      </div>
                      <div v-else>
                        <p>
                          {{
                          moment(value.transaction_date).format(" DD MMM YYYY")
                          }}
                        </p>
                        <h3 class="mt-n3">Pengalihan Berhasil</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 text-center text-lg-right my-auto">
                    <div v-if="value.transaction_type == 'Subscription'">
                      <div class="
                        col-lg-12 col-md-6
                        my-auto
                        text-center text-lg-right
                      ">
                        <div class="order-subscription">
                          <span class="text-center">Pembelian</span>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="value.transaction_type == 'Redemption'">
                      <div class="
                        col-lg-12 col-md-6
                        my-auto
                        text-center text-lg-right
                      ">
                        <div class="order-redeem">
                          <span class="text-center">Penjualan</span>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="value.transaction_type == 'Switch-In'">
                      <div class="
                        col-lg-12 col-md-6
                        my-auto
                        text-center text-lg-right
                      ">
                        <div class="order-switching">
                          <span class="text-center">Switch In</span>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="value.transaction_type == 'Reinvestment'">
                      <div class="
                        col-lg-12 col-md-6
                        my-auto
                        text-center text-lg-right
                      ">
                        <div class="order-reinvestment">
                          <span class="text-center">Reinvestment</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="
                        col-lg-12 col-md-6
                        my-auto
                        text-center text-lg-right
                      ">
                        <div class="order-switching">
                          <span class="text-center">Switch Out</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>
              </b-card>
            </template> -->
        </b-skeleton-wrapper>
      </b-container>
    </div>
    <Footer3 />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomOrders from "@/components/partials/header/HeaderBottomOrders.vue";
import Footer3 from "@/components/partials/footer/Footer3.vue";
import ButtonListActivity from '@/components/partials/button/buttonListActivity.vue'
import * as moment from "moment";
import ItemReksadana from '@/components/partials/transactions/itemReksadana.vue';
import ItemObligasi from '@/components/partials/transactions/itemObligasi.vue';

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "OnProccess",
  metaInfo: {
    title: "Riwayat Transaksi | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomOrders,
    Footer3,
    ButtonListActivity,
    ItemObligasi,
    ItemReksadana
  },
  mounted() {
    setInterval(() => {
      this.date = moment(this.date.subtract(1, "seconds"));
    }, 1000);

    Promise.all([
      // this.fetchOrders(),
      this.fetchtOrderHistories(),
      this.fetchObligasi()
    ]).catch(err => {
      console.log(err);
      alert("Error");
    }).finally(() => {
      this.loading = false;
    })
  },
  data() {
    return {
      date: moment(2460 * 60 * 1000),
      orders: [],
      orderHistories: [],
      imageCore: process.env.VUE_APP_IMAGE_URL,
      loading: true,
      // Default
      activeTab: 'semua',
      obligasi: [],
      orderBy: 'DESC',
      filterDoneOrFailedValue: 'ALL',
    };
  },
  computed: {
    time: function () {
      return this.date.format("HH:mm:ss");
    },
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
      image_core_url: "image_core_url",
      access_token: "auth/access_token",
    }),

    filterData() {
      return {
        reksadana: (this.activeTab === 'semua' || this.activeTab === 'reksadana') ? this.orderHistories : [],
        obligasi: (this.activeTab === 'semua' || this.activeTab === 'obligasi') ? this.obligasiFilter : [],
      }
    },
    obligasiFilter() {
      if (!Array.isArray(this.obligasi)) return [];
      const newObligasi = [...this.obligasi];

      return newObligasi.filter(candidate => {
        if (!this.filterDoneOrFailedValue || this.filterDoneOrFailedValue == 'ALL') return true;
        return (candidate.status == 2 && this.filterDoneOrFailedValue == 'GAGAL') || (candidate.status == 3 && this.filterDoneOrFailedValue == 'BERHASIL');
      }).sort((a, b) => {
        const aDate = new Date(a.updated_at);
        const bDate = new Date(b.updated_at);
        return this.orderBy === 'ASC' ? (aDate - bDate) : (bDate - aDate);
      })
    }
  },
  methods: {
    async fetchOrders() {
      if (this.user.institutional != null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}order-finish-get?institutional_id=${this.user.institutional.id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.orders = response.data.data;
      }
    },
    async fetchtOrderHistories() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}order-history?ifua_number=${(this.user.individual ? this.user.individual : this.user.institutional)?.ifua}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      this.orderHistories = response.data.data;
    },
    async fetchObligasi() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}fbonds/list-order-transaction?client_id=${(this.user.individual ? this.user.individual : this.user.institutional)?.client_id}&action=ordertransaction`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      // const response = {
      //   data: {
      //     "meta": {
      //       "code": 200,
      //       "status": "success",
      //       "message": "data found"
      //     },
      //     "data": [
      //       {
      //         "id": 1,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "123",
      //         "sellersales": "123",
      //         "sellerprice": "10",
      //         "sellervolume": 100,
      //         "buyerid": "RJKL7255",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 104,
      //         "buyervolume": 100000000,
      //         "transactionid": "45",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 0,
      //         "created_by": "2437",
      //         "updated_by": "2437",
      //         "created_at": "2023-10-26T04:21:50.000000Z",
      //         "updated_at": "2023-10-26T04:21:50.000000Z"
      //       },
      //       {
      //         "id": 2,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "RJKL7255",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 104,
      //         "buyervolume": 100000000,
      //         "transactionid": "50",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 0,
      //         "created_by": "2437",
      //         "updated_by": "2437",
      //         "created_at": "2023-10-26T04:24:55.000000Z",
      //         "updated_at": "2023-10-26T04:24:55.000000Z"
      //       },
      //       {
      //         "id": 3,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "RJKL7255",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 1000000000,
      //         "buyervolume": 100000000,
      //         "transactionid": "1_26102023_1",
      //         "channel": "P",
      //         "automovement": "N",
      //         "status": 0,
      //         "created_by": "2437",
      //         "updated_by": "2437",
      //         "created_at": "2023-10-26T04:39:34.000000Z",
      //         "updated_at": "2023-10-26T04:39:34.000000Z"
      //       },
      //       {
      //         "id": 4,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "2435",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 104,
      //         "buyervolume": 1040000000,
      //         "transactionid": "1_26102023_1",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 0,
      //         "created_by": "2435",
      //         "updated_by": "2435",
      //         "created_at": "2023-10-26T05:07:17.000000Z",
      //         "updated_at": "2023-10-26T05:07:17.000000Z"
      //       },
      //       {
      //         "id": 5,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "2435",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 104,
      //         "buyervolume": 1040000000,
      //         "transactionid": "1_26102023_1",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 1,
      //         "created_by": "2435",
      //         "updated_by": "2435",
      //         "created_at": "2023-10-26T05:17:37.000000Z",
      //         "updated_at": "2023-10-26T05:17:37.000000Z"
      //       },
      //       {
      //         "id": 8,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0091",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "2435",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 1,
      //         "buyervolume": 1000000,
      //         "transactionid": "202310271",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 0,
      //         "created_by": "2435",
      //         "updated_by": "2435",
      //         "created_at": "2023-10-26T06:42:17.000000Z",
      //         "updated_at": "2023-10-26T06:42:17.000000Z"
      //       },
      //       {
      //         "id": 10,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "RJKL7255",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 1000000000,
      //         "buyervolume": 100000000,
      //         "transactionid": "202310261",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 0,
      //         "created_by": "2437",
      //         "updated_by": "2437",
      //         "created_at": "2023-10-26T06:56:56.000000Z",
      //         "updated_at": "2023-10-26T06:56:56.000000Z"
      //       },
      //       {
      //         "id": 11,
      //         "action": "ordertransaction",
      //         "order_type": 1,
      //         "bond_id": "FR0071",
      //         "client_id": "RJKL7255",
      //         "sellerid": "0",
      //         "sellersales": "0",
      //         "sellerprice": "0",
      //         "sellervolume": 0,
      //         "buyerid": "RJKL7255",
      //         "buyersales": "HENDRO SUNJOTO",
      //         "buyerprice": 1000000000,
      //         "buyervolume": 100000000,
      //         "transactionid": "202310262",
      //         "channel": "P",
      //         "automovement": "Y",
      //         "status": 2,
      //         "created_by": "2437",
      //         "updated_by": "2437",
      //         "created_at": "2023-10-26T06:57:07.000000Z",
      //         "updated_at": "2023-10-26T06:57:07.000000Z"
      //       }
      //     ]
      //   }
      // }
      this.obligasi = response.data.data.filter(candidate => candidate.status != 0);
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    changeOrder(order) {
      this.orderBy = order;
    },
    changeFilterDoneOrFailed(tab) {
      this.filterDoneOrFailedValue = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity {
  background: #f3f4f6;
}

.investment-activity {
  background: white;
}

.activity-container {
  padding-top: 120px;
  margin-bottom: 0px;
}

.list-activity-card {
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.15);
  border-radius: 6px;
}

.list-activity {
  background: #f3f4f6;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.activity-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.transaction-date {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-type {
  font-weight: 700;
  font-size: 12px;
  color: #71717a;
}

.transaction-promo {
  font-weight: 700;
  font-size: 12px;
  color: #2c5282;
  background: #ebf8ff;
  border-radius: 6px;
}

.transaction-status {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.transaction-status-success {
  font-weight: 700;
  font-size: 12px;
  color: #2f855a;
  background: #f0fff4;
  border-radius: 20px;
}

.transaction-status-failed {
  font-weight: 700;
  font-size: 12px;
  color: #ce2512;
  background: #fdeeec;
  border-radius: 20px;
}

.fund-padding {
  margin-left: 20px;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    color: #1f2937;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #6b7280;
  }
}

.investment-value {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.purchased {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.on-proccess {
  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.finish {
  border-bottom: 3px solid $tertiaryColor;

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    text-decoration: none;
  }
}

.order-subscription span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #2f855a;
  background: #c6f6d5;
  border-radius: 40px;
  padding: 4px 12px 4px 12px;
}

.order-redeem span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #c53030;
  background: #fed7d7;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.order-switching span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #c05621;
  background: #feebc8;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.order-reinvestment span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #2a4365;
  background: #ebf8ff;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.timer-buy {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #e60012;
  }
}

.switch-from {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.switch-to {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
    text-align: left;
    margin: 0;
  }
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}

@media only screen and (max-width: 768px) {

  // img {
  //   display: none;
  // }
  h4 {
    font-size: 14px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  .estimation {
    p {
      text-align: left !important;
    }

    h3 {
      text-align: left !important;
    }
  }
}
</style>