<template>
  <div class="list-container">
    <Header></Header>
    <HeaderBottom></HeaderBottom>
    <b-container class="list-container-content">
      <div class="container-message" v-if="statusRequest === 'error'">
        <h1>Ada Error</h1>
        <p>Telah Terjadi error pada saat melakukan request data</p>
      </div>

      <div class="list-container-content-main" v-else>
        <div class="action" @click="$router.push('/mi')">
          <Icon src="ic_arrow_left" :size="26" />
          <p>Kembali</p>
        </div>

        <!-- Container -->
        <div class="container-content">
          <h1>Hasil Perbandingan Manajer Investasi</h1>

          <div class="content-action-reksadana">
            <h1>Jenis Reksa Dana</h1>
            <div>
              <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'semua' }"
                data-name="semua"
              >
                Semua
              </button>
              <!-- <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'ekuitas' }"
                data-name="ekuitas"
              >
                Ekuitas
              </button> -->
              <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'Campuran' }"
                data-name="Campuran"
              >
                Campuran
              </button>
              <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'Pendapatan Tetap' }"
                data-name="Pendapatan Tetap"
              >
                Pendapatan Tetap
              </button>
              <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'Pasar Uang' }"
                data-name="Pasar Uang"
              >
                Pasar Uang
              </button>
              <button
                @click="changeJenisReksadanaAktif"
                :class="{ active: jenisReksadanaAktif === 'syariah' }"
                data-name="syariah"
              >
                Syariah
              </button>
            </div>
          </div>

          <div class="content-action-compare">
            <div class="content-action-compare-item">
              <div class="header">
                <h1>Perbandingan Manajer Investasi</h1>
                <div class="spinner-container">
                  <p>Pilih nama perusahaan pembanding</p>
                </div>
              </div>
              <b-skeleton-wrapper :loading="statusRequest === 'loading'">
                <template #loading>
                  <b-skeleton type="input" width="100%"></b-skeleton>
                </template>

                <select
                  class="form-control"
                  v-if="
                    !filterGroupListNav || !Array.isArray(filterGroupListNav[0])
                  "
                  :disabled="true"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                </select>
                <select
                  class="form-control"
                  v-else
                  v-model="perbandinganProduct[0]"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                  <option
                    :key="item.item.id"
                    :value="item.item.id"
                    v-for="item in filterGroupListNav[0]"
                  >
                    {{ item.item.im_name }}
                  </option>
                </select>
              </b-skeleton-wrapper>
            </div>
            <div class="content-action-compare-item">
              <div class="header">
                <p>Pilih nama perusahaan pembanding</p>
              </div>
              <b-skeleton-wrapper :loading="statusRequest === 'loading'">
                <template #loading>
                  <b-skeleton type="input" width="100%"></b-skeleton>
                </template>

                <select
                  class="form-control"
                  v-if="
                    !filterGroupListNav || !Array.isArray(filterGroupListNav[1])
                  "
                  :disabled="true"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                </select>
                <select
                  class="form-control"
                  v-else
                  v-model="perbandinganProduct[1]"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                  <option
                    :key="item.item.id"
                    :value="item.item.id"
                    v-for="item in filterGroupListNav[1]"
                  >
                    {{ item.item.im_name }}
                  </option>
                </select>
              </b-skeleton-wrapper>
            </div>
            <div class="content-action-compare-item">
              <div class="header">
                <p>Pilih nama perusahaan pembanding</p>
              </div>

              <b-skeleton-wrapper :loading="statusRequest === 'loading'">
                <template #loading>
                  <b-skeleton type="input" width="100%"></b-skeleton>
                </template>

                <select
                  class="form-control"
                  v-if="
                    !filterGroupListNav || !Array.isArray(filterGroupListNav[2])
                  "
                  :disabled="true"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                </select>
                <select
                  class="form-control"
                  v-else
                  v-model="perbandinganProduct[2]"
                >
                  <option value="" disabled selected>
                    Pilih Manajer Investasi
                  </option>
                  <option
                    :key="item.item.id"
                    :value="item.item.id"
                    v-for="item in filterGroupListNav[2]"
                  >
                    {{ item.item.im_name }}
                  </option>
                </select>
              </b-skeleton-wrapper>
            </div>
          </div>

          <div class="content-action">
            <button
              class="primary"
              :disabled="buttonSection1Disabled"
              @click="section1Complate = true"
            >
              Bandingkan
            </button>
          </div>
        </div>

        <!-- Container -->
        <div class="container-content" v-if="section1Complate">
          <h1>Perbandingan Dana Kelolaan</h1>

          <div class="content-action-compare-type">
            <button
              data-name="dana"
              :class="{ active: perbandinganAktif === 'dana' }"
              @click="changePerbandinganAktif"
            >
              Perbandingan Dana Kelolaan
            </button>
            <button
              data-name="jumlah"
              :class="{ active: perbandinganAktif === 'jumlah' }"
              @click="changePerbandinganAktif"
            >
              Perbandingan Jumlah Unit
            </button>
          </div>

          <div
            class="content-action-reksadana"
            v-if="perbandinganAktif === 'jumlah'"
          >
            <h1>Periode</h1>
            <div>
              <button
                data-name="3BL"
                :class="{ active: periodeAktif === '3BL' }"
                @click="changePriodeAktif"
              >
                3BL
              </button>
              <button
                data-name="6BL"
                :class="{ active: periodeAktif === '6BL' }"
                @click="changePriodeAktif"
              >
                6BL
              </button>
              <!-- <button
                data-name="9BL"
                :class="{ active: periodeAktif === '9BL' }"
                @click="changePriodeAktif"
              >
                9BL
              </button> -->
              <button
                data-name="1TH"
                :class="{ active: periodeAktif === '1TH' }"
                @click="changePriodeAktif"
              >
                1TH
              </button>
              <!-- <button
                data-name="2TH"
                :class="{ active: periodeAktif === '2TH' }"
                @click="changePriodeAktif"
              >
                2TH
              </button> -->
              <button
                data-name="3TH"
                :class="{ active: periodeAktif === '3TH' }"
                @click="changePriodeAktif"
              >
                3TH
              </button>
              <button
                data-name="YTD"
                :class="{ active: periodeAktif === 'YTD' }"
                @click="changePriodeAktif"
              >
                YTD
              </button>
            </div>
          </div>

          <div class="content-action-compare-date">
            <div class="content-action-compare-item">
              <div class="header">
                <h1>Dari</h1>
              </div>
              <div class="content">
                <select
                  class="form-control"
                  disabled
                  v-if="!Array.isArray(dataDateBulanFilter.dari)"
                >
                  <option value="" disabled selected>Pilih Bulan</option>
                </select>
                <select class="form-control" v-else v-model="dari.bulan">
                  <option value="" disabled selected>Pilih Bulan</option>
                  <option
                    :value="item"
                    v-for="item in dataDateBulanFilter.dari"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <select
                  class="form-control"
                  disabled
                  v-if="!Array.isArray(dataDateTahunFilter.dari)"
                >
                  <option value="" disabled selected>Pilih Tahun</option>
                </select>
                <select class="form-control" v-else v-model="dari.tahun">
                  <option value="" disabled selected>Pilih Tahun</option>
                  <option
                    :value="item"
                    v-for="item in dataDateTahunFilter.dari"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="content-action-compare-item">
              <div class="header">
                <h1>Sampai</h1>
              </div>
              <div class="content">
                <select
                  class="form-control"
                  disabled
                  v-if="!Array.isArray(dataDateBulanFilter.sampai)"
                >
                  <option value="" disabled selected>Pilih Bulan</option>
                </select>
                <select class="form-control" v-else v-model="sampai.bulan">
                  <option value="" disabled selected>Pilih Bulan</option>
                  <option
                    :value="item"
                    v-for="item in dataDateBulanFilter.sampai"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <select
                  class="form-control"
                  disabled
                  v-if="!Array.isArray(dataDateTahunFilter.sampai)"
                >
                  <option value="" disabled selected>Pilih Tahun</option>
                </select>
                <select class="form-control" v-else v-model="sampai.tahun">
                  <option value="" disabled selected>Pilih Tahun</option>
                  <option
                    :value="item"
                    v-for="item in dataDateTahunFilter.sampai"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="content-action">
            <button
              class="primary"
              :disabled="buttonSection2Disabled"
              @click="section2Complate = true"
            >
              Cek Perbandingan
            </button>
          </div>
        </div>

        <!-- Container Chart -->
        <div
          class="container-chart"
          v-if="section1Complate && section2Complate"
        >
          <!-- <div>
            <canvas ref="chart1"></canvas>
          </div>
          <div>
            <canvas ref="chart2"></canvas>
          </div> -->
          <ChartComponent
            v-if="!!listNavBasedOnFilterForChart"
            :options="chartOptions"
            :data="listNavBasedOnFilterForChart"
            :show-title="false"
          />
          <!-- <ChartComponent v-if="!!listNavBasedOnFilterForChart" :options="chartOptions" -->
          <!-- :data="listNavBasedOnFilterForChart" :show-title="false" /> -->
        </div>
      </div>
    </b-container>

    <b-container
      class="list-container-content-2"
      v-if="section1Complate && section2Complate"
    >
      <!-- Untuk chart perbandingan item -->
      <div
        class="container-compare"
        v-if="Array.isArray(listNavBasedOnFilterForItems)"
      >
        <div
          class="compare-item"
          :key="item[0].id"
          v-for="item in listNavBasedOnFilterForItems"
        >
          <div class="compare-item-content">
            <div class="compare-item-content-header">
              <img :src="`${imageUrl}/images/${item[0].image}`" />
              <h1>{{ item[0].fund_name }}</h1>
              <p>{{ item[0].im_name }}</p>
            </div>
            <div class="compare-item-content-header-detail">
              <div>
                <h1>Jenis Reksa Dana</h1>
                <p>{{ item[0].fund_type }}</p>
              </div>
              <div>
                <h1>Tanggal Peluncuran</h1>
                <p>{{ moment(item[0].nav_date).format("D - MMM - YYYY") }}</p>
              </div>
            </div>

            <div class="line"></div>

            <div class="compare-item-content-detail">
              <div class="compare-item-content-detail-item">
                <p>NAB/Unit</p>
                <p>{{ formatPrice(item[0].nav_per_unit) }}</p>
              </div>
              <div class="compare-item-content-detail-item">
                <p>Dana Kelolaan Terakhir</p>
                <p>{{ formatPrice(item[0].navAum) }}</p>
              </div>
              <div class="compare-item-content-detail-item">
                <p>Jumlah Unit Terakhir</p>
                <p>{{ formatPrice(item[0].navUnit) }}</p>
              </div>
              <div class="compare-item-content-detail-item">
                <p>Profil Risiko</p>
                <p>{{ checkResikoNav(item[0].fund_type) }}</p>
              </div>
            </div>

            <div class="line"></div>

            <div class="compare-item-content-pertumbuhan">
              <h1 class="compare-item-content-pertumbuhan-header">
                Pertumbuhan (%)
              </h1>
              <div class="compare-item-content-pertumbuhan-content">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>3Bln</th>
                      <th>6Bln</th>
                      <th>1Th</th>
                      <th>3Th</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>NAB</td>
                      <td>{{ formatPrice(item[0].navThreeMonth) }}</td>
                      <td>{{ formatPrice(item[0].navSixMonth) }}</td>
                      <td>{{ formatPrice(item[0].navOneYear) }}</td>
                      <td>{{ formatPrice(item[0].navThreeYear) }}</td>
                    </tr>
                    <!-- <tr>
                      <td>AUM</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>UNIT</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>

            <div class="line"></div>

            <div class="compare-item-content-informasi-biaya">
              <h1 class="compare-item-content-informasi-biaya-header">
                Informasi Biaya
              </h1>
              <div class="compare-item-content-informasi-biaya-content">
                <div class="compare-item-content-informasi-biaya-content-item">
                  <p>Biaya Pembelian</p>
                  <p>{{ formatPrice(item[0].fee_buy_text) || "Tidak ada" }}</p>
                </div>
                <div class="compare-item-content-informasi-biaya-content-item">
                  <p>Biaya Penjualan</p>
                  <p>{{ formatPrice(item[0].fee_sell_text) || "Tidak ada" }}</p>
                </div>
                <!-- <div class="compare-item-content-informasi-biaya-content-item">
                  <p>Biaya Pengalihan</p>
                  <p>{{ item[0].fee_switch_text || "Tidak ada" }}</p>
                </div> -->
              </div>
            </div>

            <div class="compare-item-content-informasi-transaksi">
              <h1 class="compare-item-content-informasi-transaksi-header">
                Informasi Transaksi
              </h1>
              <div class="compare-item-content-informasi-transaksi-content">
                <div
                  class="compare-item-content-informasi-transaksi-content-item"
                >
                  <p>Minimum Pembelian</p>
                  <p>
                    {{ item[0].fund_ccy }}
                    {{
                      item[0].fund_ccy === "IDR"
                        ? formatPrice(item[0].min_buy)
                        : formatUsd(item[0].min_buy)
                    }}
                  </p>
                </div>
                <div
                  class="compare-item-content-informasi-transaksi-content-item"
                >
                  <p>Minimum Pembelian Berikutnya</p>
                  <p>
                    {{ item[0].fund_ccy }}
                    {{
                      item[0].fund_ccy === "IDR"
                        ? formatPrice(item[0].min_buy_next)
                        : formatUsd(item[0].min_buy_next)
                    }}
                  </p>
                </div>
                <div
                  class="compare-item-content-informasi-transaksi-content-item"
                >
                  <p>Minimum Balance</p>
                  <p>
                    {{ item[0].fund_ccy }}
                    {{
                      item[0].fund_ccy === "IDR"
                        ? formatPrice(item[0].min_sell)
                        : formatUsd(item[0].min_sell)
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="compare-item-content-watchlist">
              <p>Add To Watchlist</p>
            </div>
          </div>

          <div class="content-item-download">
            <button>
              <a
                target="_blank"
                :href="`${imageCore}/prospectus/download/${item[0].prospectus}`"
              >
                <Icon src="ic_download" :size="24" />Prospectus
              </a>
            </button>
            <button>
              <a
                target="_blank"
                :href="`${imageCore}/fund_fact_sheet/download/${item[0].fund_fact_sheet}`"
              >
                <Icon src="ic_download" :size="24" />Fund FactSheet
              </a>
            </button>
          </div>

          <button
            class="beli"
            @click="
              redirectButton(
                `/subscription-product-detail/${item[0].fund_code}/${item[0].id}/${item[0].fundNameFormatted}`,
                isUserSyariah && item[0].is_sharia !== 1
              )
            "
          >
            Beli
          </button>
        </div>
      </div>
    </b-container>

    <b-container
      class="list-container-content-3"
      v-if="section1Complate && section2Complate"
    >
      <div class="list-container-content-main">
        <div class="container-information-additional">
          <div class="container-information-additional-header">
            <h1>Keterangan</h1>
            <p>
              AUM (IDR Milyar) AUM (USD Juta) UNIT (IDR Juta) UNIT (USD Ribu)
            </p>
          </div>

          <div class="container-information-additional-content">
            <p>
              <span>Disclaimer</span><br />
              Setiap keputusan investasi merupakan keputusan nasabah, sehingga
              tanggung jawab ada pada masing-masing nasabah yang membuat
              keputusan investasi tersebut. PT CGS International Sekuritas
              Indonesia tidak bertanggung jawab atas segala keputusan investasi
              yang dilakukan oleh siapapun baik yang memberikan keuntungan
              ataupun kerugian dengan segala kondisi dan situasi apapun juga.
              Semua data yang tertera pada artikel merupakan data dari pihak
              luar dan tidak dapat digunakan sebagai jaminan atas dasar
              perhitungan untuk membeli atau menjual suatu efek. Data-data
              tersebut merupakan data historis yang menggambarkan kinerja di
              masa lalu dan bukan merupakan jaminan atas kinerja efek tersebut
              di masa mendatang. Investor wajib membaca, memahami prospektus dan
              ringkasan reksa dana yang terdapat pada halaman ini.
            </p>
          </div>
        </div>
      </div>
    </b-container>

    <b-modal
      v-model="modalSyariah"
      size="md"
      class="modal-failed-register"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <!-- <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              /> -->
        <h1 class="p-4 my-2 text-dark">Tidak Diijinkan</h1>
        <p>Anda terdaftar sebagai nasabah Syariah</p>
        <b-row class="justify-content-md-end btn-submit-row mb-4">
          <div class="col-lg-12 col-md-6">
            <b-button
              size="sm"
              class="btn-tertiary px-4 py-2"
              @click="modalSyariah = false"
              >Tutup</b-button
            >
          </div>
        </b-row>
      </div>
    </b-modal>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import Icon from "@/components/partials/common/icon.vue";
import Axios from "axios";
import moment from "moment";
import ChartComponent from "@/components/partials/chart/index.vue";
import { mapGetters } from "vuex";
import numeral from "numeral";

// Constant Jenis Nav
// const jenisNav = [
//     "Pasar Uang",
//     "Saham",
//     "Pendapatan Tetap",
//     "Indeks",
//     "Campuran",
//     "Terproteksi"
// ];

const InstanceAxios = Axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

export default {
  name: "CompareManajerInvestasi",
  metaInfo: {
    title: "Compare Manajer Investasi | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
    Icon,
    ChartComponent,
  },
  data() {
    return {
      // env
      imageUrl: process.env.VUE_APP_IMAGE_URL,
      /**
       * state filter
       */
      jenisReksadanaAktif: "semua",
      perbandinganProduct: ["", "", ""],
      section1Complate: false,

      // sec 2
      periodeAktif: "3BL",
      perbandinganAktif: "dana",
      dari: {
        bulan: "",
        tahun: "",
      },
      sampai: {
        bulan: "",
        tahun: "",
      },
      section2Complate: false,
      /**
       * State data
       */
      listManagerInvestasi: null,
      listNav: null,
      statusRequest: "iddle", // iddle, loading, error
      imageCore: process.env.VUE_APP_IMAGE_URL,
      modalSyariah: false,
    };
  },
  methods: {
    redirectButton(url, disabled) {
      if (disabled) {
        this.modalSyariah = true;
      } else {
        this.$router.push(url);
      }
    },
    changeJenisReksadanaAktif(event) {
      this.jenisReksadanaAktif = event.target.dataset.name;
    },
    changePriodeAktif(event) {
      this.periodeAktif = event.target.dataset.name;
    },
    changePerbandinganAktif(event) {
      this.perbandinganAktif = event.target.dataset.name;
    },
    checkResikoNav(value) {
      const resiko = {
        "Pasar Uang": "KONSERVATIVE",
        "Pendapatan Tetap": "KONSERVATIVE",
        Saham: "AGRESIF",
        Indeks: "AGRESIF",
        Campuran: "MODERATE",
        Terproteksi: "MODERATE",
      };

      return resiko[value] ?? "Unknown";
    },
    checkDate() {
      if (this.dari.bulan === this.sampai.bulan) {
        this.dari.bulan = "";
        this.sampai.bulan = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isUserSyariah: "auth/isUserSyariah",
    }),
    dataBulan() {
      return [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "July",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
    },
    dataTahun() {
      const tahunSekarang = new Date().getFullYear();
      const result = [];
      for (let i = tahunSekarang; i > tahunSekarang - 10; i--) {
        result.push(i);
      }
      return result;
    },
    dataDateBulanFilter() {
      // Jajal bulan dulu aja
      if (!this.dari.tahun || !this.sampai.tahun) {
        return {
          dari: this.dataBulan,
          sampai: this.dataBulan,
        };
      }

      // Jika tahun dari sama dengan tahun sampai maka
      // harus diperlakukan secara khusus
      if (this.dari.tahun === this.sampai.tahun) {
        if (!this.dari.bulan && !this.sampai.bulan) {
          return {
            dari: this.dataBulan,
            sampai: this.dataBulan,
          };
        }

        const indexDari = this.dataBulan.findIndex(
          (candidate) => candidate === this.dari.bulan
        );
        const sampaiDari = this.dataBulan.findIndex(
          (candidate) => candidate === this.sampai.bulan
        );

        return {
          dari: !this.sampai.bulan
            ? [...this.dataBulan]
            : this.dataBulan.slice(0, sampaiDari),
          sampai: !this.dari.bulan
            ? [...this.dataBulan]
            : this.dataBulan.slice(indexDari + 1),
        };
      }

      return {
        dari: this.dataBulan,
        sampai: this.dataBulan,
      };
    },
    dataDateTahunFilter() {
      // Jajal bulan dulu aja
      if (!this.dari.tahun && !this.sampai.tahun) {
        return {
          dari: this.dataTahun,
          sampai: this.dataTahun,
        };
      }

      const dariIndex = this.dataTahun.findIndex(
        (candidate) => candidate === this.dari.tahun
      );
      const sampaiIndex = this.dataTahun.findIndex(
        (candidate) => candidate === this.sampai.tahun
      );

      return {
        dari: this.sampai.tahun
          ? this.dataTahun.slice(sampaiIndex)
          : [...this.dataTahun],
        sampai: this.dari.tahun
          ? this.dataTahun.slice(0, dariIndex + 1)
          : [...this.dataTahun],
      };
    },
    // Untuk select options product
    filterGroupListNav() {
      // Dapetin type data nav yang tersedia
      // console.log(
      //   this.listNav.reduce(function (prev, current) {
      //     const newPrev = [...prev];
      //     const find = prev.find(candidate => candidate === current.fund_type);

      //     if (!find) {
      //       newPrev.push(current.fund_type);
      //     }

      //     return newPrev
      //   }, [])
      // )
      if (!this.listManagerInvestasi || !this.listNav) return null;

      const group = this.listManagerInvestasi.map((item) => {
        return {
          item: { ...item },
          groupType: this.listNav
            .filter((candidate) => candidate.im_id === item.id)
            .map((candidate) =>
              candidate.is_sharia === 1 ? "syariah" : candidate.fund_type
            )
            .reduce((prev, val) => {
              const newPrev = [...prev];
              if (!newPrev.includes(val)) newPrev.push(val);
              return newPrev;
            }, []),
        };
      });

      const groupBasedOnJenis =
        this.jenisReksadanaAktif === "semua"
          ? group
          : group.filter((candidate) =>
              candidate.groupType.find(
                (candidateGroup) => candidateGroup === this.jenisReksadanaAktif
              )
            );

      const kecualiMasingMasing = [
        [...this.perbandinganProduct],
        [...this.perbandinganProduct],
        [...this.perbandinganProduct],
      ];

      kecualiMasingMasing[0].splice(0, 1);
      kecualiMasingMasing[1].splice(1, 1);
      kecualiMasingMasing[2].splice(2, 1);

      return [
        groupBasedOnJenis.filter(
          ({ item }) => !kecualiMasingMasing[0].includes(item.id)
        ),
        groupBasedOnJenis.filter(
          ({ item }) => !kecualiMasingMasing[1].includes(item.id)
        ),
        groupBasedOnJenis.filter(
          ({ item }) => !kecualiMasingMasing[2].includes(item.id)
        ),
      ];
    },
    buttonSection1Disabled() {
      return !this.jenisReksadanaAktif || this.perbandinganProduct.includes("");
    },
    buttonSection2Disabled() {
      return (
        !this.periodeAktif ||
        !this.perbandinganAktif ||
        !this.dari.bulan ||
        !this.dari.tahun ||
        !this.sampai.bulan ||
        !this.sampai.tahun
      );
    },
    // Groupping
    groupListNavBasedOnMI() {
      return !this.listNav || !this.perbandinganProduct
        ? null
        : this.listNav
            .filter((candidate) => {
              return !!this.perbandinganProduct.find(
                (candidateItem) => candidateItem === candidate.im_id
              );
            })
            .reduce((prev, val) => {
              const prevNew = { ...prev };

              // Convert created_at to a date string (only the day part)
              const createdAtDay = new Date(val.created_at)
                .toISOString()
                .split("T")[0];

              // Check if the entry already exists for this im_id and created_at day
              if (Object.hasOwn(prevNew, val.im_id)) {
                const existingGroup = prevNew[val.im_id].find(
                  (group) => group.created_at === createdAtDay
                );

                if (existingGroup) {
                  // If a group for the same im_id and date exists, sum the navAum
                  existingGroup.navAum += parseFloat(val.navAum);
                } else {
                  // If not, create a new group for this date
                  prevNew[val.im_id].push({
                    ...val,
                    created_at: createdAtDay,
                    navAum: parseFloat(val.navAum),
                  });
                }
              } else {
                // If this im_id doesn't exist yet, create a new entry
                prevNew[val.im_id] = [
                  {
                    ...val,
                    created_at: createdAtDay,
                    navAum: val.navAum,
                  },
                ];
              }

              return prevNew;
            }, {});
    },
    // Untuk chart perbandingan item
    listNavBasedOnFilterForItems() {
      if (!this.groupListNavBasedOnMI || !this.perbandinganAktif) return null;

      const arrayGroupListNavBasedOnMI = Object.entries(
        this.groupListNavBasedOnMI
      );
      return arrayGroupListNavBasedOnMI.map((candidate) =>
        candidate[1]
          .sort((a, b) => {
            const bandingkanKey =
              this.perbandinganAktif === "dana" ? "navAum" : "nav_per_unit";
            return parseFloat(a[bandingkanKey]) - parseFloat(b[bandingkanKey]);
          })
          .slice(-1)
      );
    },
    listNavBasedOnFilterForChart() {
      if (
        !this.perbandinganAktif ||
        !this.groupListNavBasedOnMI ||
        !this.listManagerInvestasi ||
        !this.dari.tahun ||
        !this.dari.bulan ||
        !this.sampai.tahun ||
        !this.sampai.bulan
      )
        return null;

      // khusus unit
      if (this.perbandinganAktif === "jumlah" && !this.periodeAktif)
        return null;

      const ArrayGroupListNavBasedOnMI = Object.entries(
        this.groupListNavBasedOnMI
      );

      console.log(this.groupListNavBasedOnMI);

      const metaBorderBackground = [
        {
          borderColor: "rgb(3, 138, 255)",
          pointBackgroundColor: "rgb(11, 127, 171)",
        },
        {
          borderColor: "rgb(38, 194, 129)",
          pointBackgroundColor: "rgb(46, 204, 113)",
        },
        { borderColor: "#333", pointBackgroundColor: "#000" },
      ];

      return {
        datasets: ArrayGroupListNavBasedOnMI.map((candidate, index) => {
          return {
            ...metaBorderBackground[index],
            borderWidth: 2,
            backgroundColor: "transparent",
            label:
              this.listManagerInvestasi.find(
                (candidateItem) =>
                  parseInt(candidateItem.id) === parseInt(candidate[0])
              )?.im_name || "Tidak diketahui",
            data: candidate[1]
              .filter((candidateitem) => {
                const indexDari = this.dataBulan.findIndex(
                  (candidate) => candidate === this.dari.bulan
                );
                const sampaiDari = this.dataBulan.findIndex(
                  (candidate) => candidate === this.sampai.bulan
                );

                const sebelum = moment(
                  new Date(`${this.dari.tahun}-${indexDari + 1}`)
                ).subtract(1, "days");
                const sampai = moment(
                  new Date(`${this.sampai.tahun}-${sampaiDari + 1}`)
                ).add(1, "days");

                return (
                  moment(candidateitem.created_at).isAfter(sebelum) &&
                  moment(candidateitem.created_at).isBefore(sampai)
                );
              })
              .sort((a, b) => new Date(a) - new Date(b))
              .map((candidate) => {
                let kunci =
                  this.perbandinganAktif === "dana" ? "navAum" : "unit";

                if (kunci === "unit") {
                  switch (this.periodeAktif) {
                    case "3BL": {
                      kunci = "navThreeMonth";
                      break;
                    }
                    case "6BL": {
                      kunci = "navSixMonth";
                      break;
                    }
                    case "9BL": {
                      kunci = "navNineMonth";
                      break;
                    }
                    case "1TH": {
                      kunci = "navOneYear";
                      break;
                    }
                    case "2TH": {
                      kunci = "navTwoYear";
                      break;
                    }
                    case "3TH": {
                      kunci = "navThreeYear";
                      break;
                    }
                    case "YTD": {
                      kunci = "navYtd";
                      break;
                    }
                  }
                }

                return {
                  x: new Date(candidate.created_at).getTime(),
                  y: parseFloat(candidate[kunci] || 0),
                };
              }),
          };
        }),
      };

      // Opsi backup
      // return {
      //   datasets: [
      //     {
      //       label: request[0]?.im_name?.im_name || "Tidak diketahui",
      //       data: request[0]?.items,
      //       backgroundColor: "transparent",
      //       borderColor: "rgb(3, 138, 255)",
      //       pointBackgroundColor: "rgb(11, 127, 171)",
      //       borderWidth: 2,
      //     },
      //     {
      //       label: request[1]?.im_name?.im_name || "Tidak diketahui",
      //       data: request[1]?.items,
      //       backgroundColor: "transparent",
      //       borderColor: "rgb(38, 194, 129)",
      //       pointBackgroundColor: "rgb(46, 204, 113)",
      //       borderWidth: 2,
      //     },
      //     {
      //       label: request[2]?.im_name?.im_name || "Tidak diketahui",
      //       data: request[2]?.items,
      //       backgroundColor: "transparent",
      //       borderColor: "#333",
      //       pointBackgroundColor: "#000",
      //       borderWidth: 2,
      //     },
      //   ],
      // };
    },
    chartOptions() {
      return {
        tooltips: {
          // enabled: false,
          callbacks: {
            title: (val) => moment(val[0].xLabel).format("D MMM YYYY"),
          },
        },
        scales: {
          yAxes: [
            {
              // stepSize: 100,
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => `${numeral(value).format("0,0")}`,
                beginAtZero: true,
                padding: 10,
              },
            },
          ],
          xAxes: [
            {
              type: "linear",
              position: "bottom",
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => moment(value).format("MMM YYYY"),
                padding: 10,
              },
            },
          ],
        },
        datasets: {
          line: {
            pointHoverBorderColor: "#FFF",
            spanGaps: true,
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontSize: 13,
            fontColor: "#000",
            fontStyle: "bold",
          },
        },
        title: {
          display: true,
          text: "Perbandingan Dana Kelolaan (IDR)",
          fontColor: "#444",
          fontSize: "18",
          fontStyle: "normal",
        },
      };
    },
  },
  mounted() {
    async function requestData() {
      try {
        this.statusRequest = "loading";
        const request = await InstanceAxios("api/core/investment-manager");
        const resultData = request?.data?.data;

        // Request 2
        const request2 = await InstanceAxios(
          "api/core/nav-history?api=1&type=1"
        );
        const resultData2 = request2?.data?.dataNav;

        // Cek struktur
        // Cek struktur dari respon
        if (
          !resultData ||
          !Array.isArray(resultData) ||
          !resultData2 ||
          !Array.isArray(resultData2)
        ) {
          throw new Error("Struktur respond yang diterima tidak sesuai");
        }

        this.listManagerInvestasi = resultData;
        this.listNav = resultData2;

        this.statusRequest = "iddle";
      } catch (err) {
        this.statusRequest = "error";
        console.log("\n\n================================");
        console.log(err);
        alert("ERROR BRO");
      }
    }

    requestData.call(this);
  },
  watch: {
    listManagerInvestasi: {
      flush: "post",
      handler(newValue) {
        if (Array.isArray(newValue)) {
          const { product1, product2, product3 } = this.$route.query;
          const kecualiMasingMasing = [
            [...this.perbandinganProduct],
            [...this.perbandinganProduct],
            [...this.perbandinganProduct],
          ];
          const complate = [false, false, false];
          kecualiMasingMasing[0].splice(0, 1);
          kecualiMasingMasing[1].splice(1, 1);
          kecualiMasingMasing[2].splice(2, 1);

          if (
            product1 &&
            newValue.find((candidate) => candidate.id === parseInt(product1)) &&
            !kecualiMasingMasing[0].includes(parseInt(product1))
          ) {
            const newPerbandingan = [...this.perbandinganProduct];
            newPerbandingan[0] = parseInt(product1);
            this.perbandinganProduct = newPerbandingan;
            complate[0] = true;
          }

          if (
            product2 &&
            newValue.find((candidate) => candidate.id === parseInt(product2)) &&
            !kecualiMasingMasing[1].includes(parseInt(product2))
          ) {
            const newPerbandingan = [...this.perbandinganProduct];
            newPerbandingan[1] = parseInt(product2);
            this.perbandinganProduct = newPerbandingan;
            complate[1] = true;
          }

          if (
            product3 &&
            newValue.find((candidate) => candidate.id === parseInt(product3)) &&
            !kecualiMasingMasing[2].includes(parseInt(product3))
          ) {
            const newPerbandingan = [...this.perbandinganProduct];
            newPerbandingan[2] = parseInt(product3);
            this.perbandinganProduct = newPerbandingan;
            complate[2] = true;
          }

          // Jika sudah semua
          if (!complate.includes(false)) {
            this.section1Complate = true;
          }
        }
      },
    },
    "dari.tahun"(newValue) {
      if (newValue && this.sampai.tahun && newValue === this.sampai.tahun) {
        this.checkDate();
      }
    },
    "sampai.tahun"(newValue) {
      if (newValue && this.dari.tahun && newValue === this.dari.tahun) {
        this.checkDate();
      }
    },
    jenisReksadanaAktif() {
      this.perbandinganProduct = ["", "", ""];
      this.section1Complate = false;
    },
  },
};
</script>

<style scoped lang="scss">
.list-container {
  width: 100%;
  background-color: #f3f4f6;

  @mixin button() {
    padding: 12px 16px;
    background-color: #0b318f;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: white;
    border: 0;
  }

  .list-container-content {
    padding: $marginTopForContent 16px 20px;

    @media screen and (min-width: $lg) {
      & {
        padding: $marginTopForContent 16px 50px;
      }
    }

    .container-message {
      width: 100%;
      box-shadow: 0px 2px 4px rgba(107, 114, 128, 0.06),
        0px 4px 6px rgba(107, 114, 128, 0.15);
      background-color: white;
      padding: 20px;
      gap: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      h1 {
        font-size: 30px;
        font-weight: bold;
      }

      p {
        font-size: 18px;
      }
    }

    .list-container-content-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #908f94;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;

        p {
          margin-left: 7px;
          line-height: 150%;
          margin: 0;
        }

        svg {
          font-size: 16px;
        }
      }

      .container-content {
        box-shadow: 0px 2px 4px rgba(107, 114, 128, 0.06),
          0px 4px 6px rgba(107, 114, 128, 0.15);
        background-color: white;
        padding: 20px;
        gap: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @media screen and (min-width: $lg) {
          & {
            padding: 40px;
            gap: 40px;
          }
        }

        h1 {
          font-weight: bold;
          font-size: 22px;
          line-height: 150%;
          color: black;
          text-align: center;

          @media screen and (min-width: $md) {
            & {
              font-size: 24px;
            }
          }

          @media screen and (min-width: $lg) {
            & {
              font-size: 26px;
            }
          }

          @media screen and (min-width: $xl) {
            & {
              font-size: 28px;
            }
          }
        }

        .content-action-compare-type {
          display: flex;
          gap: 10px;
          flex-direction: column;

          @media screen and (min-width: $sm) {
            & {
              gap: 16px;
              flex-direction: row;
            }
          }

          button {
            padding: 10px 24px;
            border-radius: 8px;
            border: 0;
            outline: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #3d3d3d;
            background: #d1deff;
          }

          button.active {
            background: #0b318f;
            color: white;
          }
        }

        .content-action-reksadana {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          @media screen and (min-width: $sm) {
            & {
              h1 {
                font-size: 18px;
              }
            }
          }

          @media screen and (min-width: $lg) {
            & {
              gap: 16px;
              width: initial !important;

              div {
                flex-direction: row !important;
                width: initial !important;

                button {
                  width: initial !important;
                }
              }
            }
          }

          h1 {
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: black;
          }

          div {
            display: flex;
            flex-direction: column;
            gap: 9px;
            width: 100%;

            button {
              padding: 8px 12px;
              background: #f5f5f5;
              border: 1px solid #cccccc;
              border-radius: 4px;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              text-align: center;

              width: 100%;
            }

            button.active {
              background: #ffe7e9;
              border: 1px solid #ffbdc3;
              color: #e60012;
            }
          }
        }

        .content-action-compare-date {
          width: 100%;
          display: grid;
          align-items: end;
          gap: 0px;

          @media screen and (min-width: $lg) {
            & {
              gap: 43px !important;
              grid-template-columns: repeat(2, 1fr) !important;

              .content-action-compare-item .content {
                flex-direction: row !important;
              }
            }
          }

          .content-action-compare-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 0px 24px;
            gap: 8px;

            .header {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 4px;

              h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #374151;
              }
            }

            .content {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 20px;
              flex-direction: column;

              select {
                background: #ffffff;
                border: 1px solid #d1d5db;
                border-radius: 5px;
              }
            }
          }
        }

        .content-action-compare {
          width: 100%;
          display: grid;
          align-items: end;
          gap: 10px;

          @media screen and (min-width: $lg) {
            & {
              gap: 43px !important;
              grid-template-columns: repeat(3, 1fr) !important;

              .content-action-compare-item {
                .header {
                  h1 {
                    font-size: 18px !important;
                  }
                }
              }
            }
          }

          .content-action-compare-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 0px 24px;
            gap: 8px;

            .b-skeleton-wrapper {
              width: 100% !important;
            }

            .header {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 4px;

              h1 {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #374151;
              }

              p {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #6b7280;
                margin: 0;
              }

              // .spinner-container {
              //   display: flex;
              //   flex-direction: row;
              //   flex-wrap: wrap;
              //   align-items: center;
              //   gap: 8px;
              // }
            }

            select {
              background: #ffffff;
              border: 1px solid #d1d5db;
              border-radius: 5px;
            }
          }
        }

        .content-action {
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 10px;
          gap: 15px;

          @media screen and (min-width: $md) {
            & {
              button {
                width: initial !important;
              }
            }
          }

          button {
            @include button();
            width: 100%;
          }
        }
      }

      .container-chart {
        width: 100%;
        display: grid;
        gap: 30px;
        overflow: auto;

        @media screen and (min-width: $xl) {
          & {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        div {
          width: 100%;
          height: 100%;
          padding: 10px;
          border-radius: 20px;
          background-color: white;
          position: relative;
        }
      }

      .container-information-additional {
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .container-information-additional-header {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          font-style: normal;
          line-height: 150%;
          text-align: justify;
          color: #000000;

          h1 {
            font-weight: 700;
            font-size: 18px;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            margin: 0;
          }
        }
      }

      button.primary {
        @include button();
        width: 100%;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        @media screen and (min-width: $md) {
          & {
            width: initial;
          }
        }
      }
    }
  }

  .list-container-content-3 {
    padding: 20px 16px;

    @media screen and (min-width: $lg) {
      & {
        padding: 50px 16px;
      }
    }

    .list-container-content-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .container-information-additional {
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .container-information-additional-header {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          font-style: normal;
          line-height: 150%;
          text-align: justify;
          color: #000000;

          h1 {
            font-weight: 700;
            font-size: 18px;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            margin: 0;
          }
        }

        .container-information-additional-content {
          width: 100%;
          border: 1px solid #3d3d3d;
          border-radius: 8px;
          padding: 12px 16px;

          @media screen and (min-width: $lg) {
            & {
              padding: 24px 32px !important;
            }
          }

          p {
            font-style: normal;
            font-size: 16px;
            line-height: 22px;
            text-align: justify;
            color: #000000;

            span {
              font-weight: 700;
            }
          }
        }
      }

      button.primary {
        @include button();
        width: 100%;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        @media screen and (min-width: $md) {
          & {
            width: initial;
          }
        }
      }
    }
  }

  .list-container-content-2 {
    margin-top: 60px;
    margin-bottom: 60px;

    @media screen and (min-width: $xl2) {
      & {
        width: 1380px !important;
        max-width: 1380px !important;
      }
    }

    .container-compare {
      width: 100%;
      display: grid;
      gap: 50px;

      @media screen and (min-width: $xl) {
        & {
          gap: 20px;
          grid-template-columns: repeat(3, 1fr);
        }
      }

      .compare-item {
        width: 100%;
        height: max-content;
        border-radius: 20px;
        background-color: white;
        overflow: hidden;

        .compare-item-content {
          width: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 22px;

          .line {
            width: 100%;
            border: 1px solid #e0e0e0;
          }

          .compare-item-content-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
            gap: 14px;

            @media screen and (min-width: $md) {
              & {
                gap: 24px;

                h1 {
                  font-size: 18px !important;
                }

                p {
                  font-size: 16px !important;
                }
              }
            }

            h1 {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
              color: #3d3d3d;
              text-align: center;
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #3d3d3d;
              text-align: center;
            }

            img {
              width: 70%;
              height: 100%;
              object-fit: contain;
            }
          }

          .compare-item-content-header-detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 20px;
            flex-direction: column;

            @media screen and (min-width: $md) {
              & {
                flex-direction: row;

                div:last-child {
                  h1,
                  p {
                    text-align: right !important;
                  }
                }
              }
            }

            div h1,
            div p {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #3d3d3d;
              text-align: left;
            }

            h1 {
              font-weight: bold !important;
            }

            div {
              width: 100%;
            }
          }

          .compare-item-content-detail {
            display: grid;
            gap: 16px;
            width: 100%;
            justify-items: center;
            align-items: center;
            padding: 0 20px;

            @media screen and (min-width: $md) {
              & {
                gap: 10px;

                .compare-item-content-detail-item {
                  grid-template-columns: repeat(2, 1fr);

                  p:last-child {
                    text-align: right;
                  }
                }
              }
            }

            .compare-item-content-detail-item {
              display: grid;
              width: 100%;
              align-items: center;
              gap: 10px;

              p {
                margin: 0;
              }

              p:first-child {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                color: #3d3d3d;
                text-align: left;
              }

              p:last-child {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #3d3d3d;
              }
            }
          }

          .compare-item-content-pertumbuhan {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;

            .compare-item-content-pertumbuhan-header {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              color: #3d3d3d;
              margin: 0;
            }

            .compare-item-content-pertumbuhan-content {
              width: 100%;
              overflow: auto;

              table {
                width: 100%;

                thead tr th,
                tbody tr td:first-child {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 150%;

                  text-align: center;
                  vertical-align: middle;
                  color: #3d3d3d;
                }

                td,
                th {
                  text-align: center !important;
                  padding: 10px;
                }

                tbody tr td {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: #3d3d3d;
                }
              }
            }
          }

          .compare-item-content-informasi-biaya {
            width: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            @media screen and (min-width: $md) {
              & {
                .compare-item-content-informasi-biaya-content-item {
                  grid-template-columns: repeat(2, 1fr);

                  p:last-child {
                    text-align: right !important;
                  }
                }
              }
            }

            .compare-item-content-informasi-biaya-header {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              color: #3d3d3d;
            }

            .compare-item-content-informasi-biaya-content {
              display: grid;
              gap: 10px;
              width: 100%;
              justify-items: center;
              align-items: center;

              .compare-item-content-informasi-biaya-content-item {
                display: grid;
                width: 100%;
                align-items: center;
                gap: 10px;

                p {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 150%;
                  color: #3d3d3d;
                  margin: 0;
                  text-align: left;
                }
              }
            }
          }

          .compare-item-content-informasi-transaksi {
            width: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            @media screen and (min-width: $md) {
              & {
                gap: 15px;

                .compare-item-content-informasi-transaksi-content {
                  gap: 10px;

                  .compare-item-content-informasi-transaksi-content-item {
                    grid-template-columns: repeat(2, 1fr);

                    p:last-child {
                      text-align: right !important;
                    }
                  }
                }
              }
            }

            .compare-item-content-informasi-transaksi-header {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              color: #3d3d3d;
            }

            .compare-item-content-informasi-transaksi-content {
              display: grid;
              gap: 16px;
              width: 100%;
              justify-items: center;
              align-items: center;

              .compare-item-content-informasi-transaksi-content-item {
                display: grid;
                width: 100%;
                align-items: center;
                gap: 10px;

                p {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 150%;
                  color: #3d3d3d;
                  margin: 0;
                  text-align: left;
                }
              }
            }
          }

          .compare-item-content-watchlist {
            width: 100%;
            display: flex;
            padding: 0 20px;
            justify-content: center;
            align-items: center;

            p {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              color: #ff3f4e;
              margin: 0;
            }
          }
        }

        .content-item-download {
          width: 100%;
          display: flex;
          margin-top: 20px;

          button {
            padding: 20px;
            border: 0.5px solid #908f94;
            background-color: white;
            width: 100%;
            line-height: 150%;
            outline: 0;

            a {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              text-decoration: none;
            }

            svg {
              margin: 0 10px 0 0;
            }
          }
        }

        button.beli {
          width: 100%;
          border: 0;
          outline: 0;
          background: #0b318f;
          border-radius: 5px;
          padding: 24px 16px;
          text-align: center;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
