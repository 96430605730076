import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/obligasi",
    name: "Obligasi",
    component: () => import("../views/obligasi/Obligasi.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/obligasi/compare",
    name: "ObligasiCompare",
    component: () => import("../views/obligasi/Compare.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-fbonds-detail/:bond_id/:bond_type",
    name: "SubscriptionFbondsDetail",
    component: () =>
      import("../views/dashboard/subscription/fbonds/SubscriptionDetail.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-fbonds-review/:bond_id/:bond_type/:product_id?",
    name: "SubscriptionReviewFbonds",
    component: () =>
      import("../views/dashboard/subscription/fbonds/SubscriptionReview.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-fbonds-calculator/:bond_id/:bond_type/:product_id?",
    name: "SubscriptionCalculatorFbonds",
    component: () =>
      import(
        "../views/dashboard/subscription/fbonds/SubscriptionCalculator.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-fbonds-verification/:bond_id/:bond_type",
    name: "SubscriptionVerificationFbonds",
    component: () =>
      import(
        "../views/dashboard/subscription/fbonds/SubscriptionVerification.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: () =>
      import("../views/dashboard/activities/withdraw/Orders.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/withdraw/history",
    name: "WithdrawHistory",
    component: () =>
      import("../views/dashboard/activities/withdraw/Finish.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/reksadana",
    name: "Reksadana",
    component: () => import("../views/reksadana/Reksadana.vue"),
  },
  {
    path: "/reksadana/compare",
    name: "ReksadanaCompare",
    component: () => import("../views/reksadana/Compare.vue"),
  },
  {
    path: "/mi",
    name: "ManajerInvestasi",
    component: () => import("../views/mi/List.vue"),
  },
  {
    path: "/mi/compare",
    name: "ManajerInvestasiCompare",
    component: () => import("../views/mi/Compare.vue"),
  },
  // {
  //   path: '/career/senior-software-engineer',
  //   name: 'Career1',
  //   component: () => import('../views/career/Career1.vue')
  // },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register.vue"),
  },
  // {
  //   path: "/register/fbonds",
  //   name: "RegisterFbonds",
  //   component: () => import("../views/auth/RegisterFbonds.vue"),
  //   // meta: {
  //   //   auth: true
  //   // }
  // },
  {
    path: "/register/institutional",
    name: "RegisterInstitutional",
    component: () => import("../views/auth/RegisterInstitutional.vue"),
  },
  {
    path: "/register/:sales",
    name: "RegisterFromSales",
    component: () => import("../views/auth/RegisterFromSales.vue"),
  },
  {
    path: "/pembukaan-akun",
    name: "OpeningAccount",
    component: () => import("../views/auth/OpeningAccount.vue"),
    meta: {
      // auth: true,
    },
  },
  {
    path: "/institutional/pembukaan-akun",
    name: "registerPembukaanRekeningInstitional",
    component: () =>
      import("../views/auth/Institutional/OpenningBankAccount.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/profil-risiko",
    name: "RiskProfile",
    component: () => import("../views/auth/RiskProfile.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/auth/ResetPassword.vue"),
  },
  {
    path: "/verification",
    name: "Verification",
    component: () => import("../views/auth/Verification.vue"),
  },
  {
    path: "/portfolio",
    redirect: "/portfolio/semua",
  },
  {
    path: "/portfolio/:type",
    name: "Portfolio",
    component: () => import("../views/dashboard/portfolios/Portfolio.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/dashboard/profile/Profile.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/identity",
    name: "ProfileIdentity",
    component: () => import("../views/dashboard/profile/ProfileIdentity.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/payment-account",
    name: "ProfilePaymentAccount",
    component: () =>
      import("../views/dashboard/profile/ProfilePaymentAccount.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/setting",
    name: "Setting",
    component: () => import("../views/dashboard/profile/Setting.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/change-pin",
    name: "ChangePin",
    component: () => import("../views/auth/ChangePin.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/change-password",
    name: "ChangePassword",
    component: () => import("../views/auth/ChangePassword.vue"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: "/profile/change-email",
  //   name: "ChangeEmail",
  //   component: () => import("../views/dashboard/profile/edit/ChangeEmail.vue"),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/profile/change-risk-profile",
    name: "ChangeRiskProfile",
    component: () =>
      import("../views/dashboard/profile/edit/ChangeRiskProfile.vue"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: "/profile/change-profile-identity",
  //   name: "ChangeProfileIdentity",
  //   component: () =>
  //     import("../views/dashboard/profile/edit/ChangeProfileIdentity.vue"),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/profile/add-account",
    name: "AddAccount",
    component: () => import("../views/dashboard/profile/edit/AddAccount.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/profile/change-account",
    name: "ChangeAccount",
    component: () =>
      import("../views/dashboard/profile/edit/ChangeAccount.vue"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: "/profile/change-phone",
  //   name: "ChangePhone",
  //   component: () => import("../views/dashboard/profile/edit/ChangePhone.vue"),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/dashboard/activities/Orders.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/orders/finish",
    name: "Finish",
    component: () => import("../views/dashboard/activities/Finish.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/subscription-status/:code/:fund_code",
    name: "WaitingForSubscriptionVerification",
    component: () =>
      import(
        "../views/dashboard/activities/subscription/WaitingForSubscriptionVerification.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/subscription-checkout/:code/:fund_code",
    name: "SubscriptionCheckoutTransfer",
    component: () =>
      import(
        "../views/dashboard/activities/subscription/SubscriptionCheckoutTransfer.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/subscription-choose-payment-method/:code/:fund_code",
    name: "SubscriptionChoosePaymentMethod",
    component: () =>
      import(
        "../views/dashboard/activities/subscription/SubscriptionChoosePaymentMethod.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/switching-status/:code/:fund_code",
    name: "WaitingForSwitchingVerification",
    component: () =>
      import(
        "../views/dashboard/activities/switching/WaitingForSwitchingVerification.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/redeem-status/:code/:fund_code",
    name: "WaitingForRedeemVerification",
    component: () =>
      import(
        "../views/dashboard/activities/redeem/WaitingForRedeemVerification.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/redeem-fbonds-calculator/:bond_id/:bond_type/:product_id?",
    name: "RedeemCalculatorFbonds",
    component: () =>
      import("../views/dashboard/redeem/fbonds/RedeemCalculator.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/redeem-fbonds-review/:bond_id/:bond_type/:product_id?",
    name: "SubscriptionReviewFbonds",
    component: () =>
      import("../views/dashboard/redeem/fbonds/RedeemReview.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/redeem-fbonds-verification/:bond_id/:bond_type",
    name: "SubscriptionVerificationFbonds",
    component: () =>
      import("../views/dashboard/redeem/fbonds/RedeemVerification.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/subscription-history/:ifua/:order_id/:order_type",
    name: "OrderHistorySubscription",
    component: () =>
      import(
        "../views/dashboard/activities/subscription/OrderHistorySubscription.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/switching-history/:ifua/:order_id/:order_type",
    name: "OrderHistorySwitching",
    component: () =>
      import(
        "../views/dashboard/activities/switching/OrderHistorySwitching.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/order/redeem-history/:ifua/:order_id/:order_type",
    name: "OrderHistoryRedeem",
    component: () =>
      import("../views/dashboard/activities/redeem/OrderHistoryRedeem.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-product-detail/:fund_code/:id/(.*-)?:fund_name",
    name: "SubscriptionProductDetail",
    component: () =>
      import("../views/dashboard/subscription/SubscriptionProductDetail.vue"),
  },
  {
    path: "/subscription/:fund_code",
    name: "Subscription",
    component: () => import("../views/dashboard/subscription/Subscription.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/subscription-review/:fund_code",
    name: "SubscriptionReview",
    component: () =>
      import("../views/dashboard/subscription/SubscriptionReview.vue"),
    meta: {
      auth: true,
    },
  },
  // {
  //   path: '/subscription-checkout/:fund_code',
  //   name: 'SubscriptionCheckout',
  //   component: () => import('../views/dashboard/subscription/SubscriptionCheckout.vue'),
  //   meta: {
  //     auth: true,
  //   }
  // },
  {
    path: "/switching-list-product/:fund_code/:im_id",
    name: "SwitchingListProduct",
    component: () =>
      import("../views/dashboard/switching/SwitchingListProduct.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/switching-product-detail/:fund_code_out/:fund_code_in/:id/",
    name: "SwitchingProductDetail",
    component: () =>
      import("../views/dashboard/switching/SwitchingProductDetail.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/switching/:fund_code_out/:fund_code_in",
    name: "Switching",
    component: () => import("../views/dashboard/switching/Switching.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/switching-review/:fund_code_out/:fund_code_in",
    name: "SwitchingReview",
    component: () => import("../views/dashboard/switching/SwitchingReview.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/redeem/:fund_code",
    name: "Redeem",
    component: () => import("../views/dashboard/redeem/Redeem.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/redeem-review/:fund_code",
    name: "RedeemReview",
    component: () => import("../views/dashboard/redeem/RedeemReview.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/idle-money",
    name: "IdleMoney",
    component: () => import("../views/idlemoney/IdleMoneyMainView.vue"),
    meta: {
      auth: false,
    },
  },
  // Demo Institution
  {
    path: "/demo/register",
    name: "register",
    component: () => import("../views/auth/Institutional/Register.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/demo/register/verification",
    name: "registerVerification",
    component: () => import("../views/auth/Institutional/Verification.vue"),
    meta: {
      auth: false,
    },
  },
  // {
  //   path: "/demo/register/openning-rekening",
  //   name: "registerPembukaanRekeningInstitional",
  //   component: () =>
  //     import("../views/auth/Institutional/OpenningBankAccount.vue"),
  //   meta: {
  //     auth: false,
  //   },
  // },
  {
    path: "/demo/portfolio/:type",
    name: "PortfolioInstituonal",
    component: () =>
      import("../views/dashboard/portfolios/Institutional/Portfolio.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/idle-money/decline",
    name: "IdleMoneyDecline",
    component: () => import("../views/idlemoney/IdleMoneyDecline.vue"),
  },
  {
    path: "/iframe-withdraw",
    name: "IframeWithdraw",
    component: () => import("../views/IframeWithdraw.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../components/elements/404/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach((to, from, next) => {
  try {
    // console.log(to);
    if (to.meta.auth && !store.getters["auth/check"]) next("/");
    else next();

    if (to.name == "Home" && store.getters["auth/check"]) next("/portfolio");
    else next();
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
  }
});

export default router;
