<template>
  <div>
    <b-navbar toggleable="lg" type="dark" fixed="top" class="cgs-navbar">
      <b-container class="px-0" :class="{ 'pr-md-3': isFluid }" :fluid="isFluid">
        <template v-if="!check">
          <div class="row w-100 mx-0">
            <div class="col-6" :class="{ 'px-md-0': isFluid }">
              <b-navbar-brand :href="`${frontendLink}`">
                <img src="@/assets/img/CGSI-iTrade-Fund-LOGO_RGB.png" class="cgs-logo" alt="cgs-logo" />
              </b-navbar-brand>
            </div>
            <div class="col-6 text-right">
              <b-button-group>
                <b-button size="sm" :to="{ path: '/' }" class="
                    btn-tertiary
                    px-3
                    py-2
                    ml-3
                    mr-2
                    d-inline-block d-lg-none d-md-none buttonRounded
                  ">Masuk</b-button>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              </b-button-group>
            </div>
          </div>
        </template>

        <template v-else>
          <b-container class="px-0">
            <b-navbar-brand :href="`${frontendLink}`">
              <img src="@/assets/img/CGSI-iTrade-Fund-LOGO_RGB.png" class="cgs-logo" alt="cgs-logo" />
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </b-container>
        </template>

        <template v-if="!check">
          <b-collapse id="nav-collapse" is-nav class="p-0">
            <b-navbar-nav class="ml-auto">
              <b-navbar-nav>
                <b-nav-item class="my-auto" :href="`${frontendLink}`" exact
                  active-class="nav-active ml-auto">Home</b-nav-item>
                <b-nav-item class="my-auto" :href="`${frontendLink}/promo`" exact
                  active-class="nav-active ml-auto">Promo</b-nav-item>

                <b-dropdown id="modalRoot" class="nav-link navDropdownItem" size="md" right variant="link"
                  toggle-class="text-decoration-none text-left  px-0" no-caret text="Produk">
                  <!-- <b-dropdown-item :to="{ path: '/mi' }">Manajer Investasi</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/reksadana' }">List Reksadana</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/reksadana/compare' }">Bandingkan
                    Reksadana</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/mi/compare' }">Bandingkan Manajer
                    Investasi</b-dropdown-item> -->
                  <b-dropdown-item class="p-0">
                    <b-dropdown id="modalChild" ref="test" class="nav-link navDropdownItem" size="md" left
                      variant="link" toggle-class="text-decoration-none text-left  px-0" no-caret text="Reksadana">
                      <b-dropdown-item :to="{ path: '/mi' }">Manajer Investasi</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/reksadana' }">List Reksadana</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/reksadana/compare' }">Bandingkan
                        Reksadana</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/mi/compare' }">Bandingkan Manajer
                        Investasi</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item>
                    <b-dropdown id="modalChild3" ref="test" class="nav-link navDropdownItem" size="md" left variant="link"
                      toggle-class="text-decoration-none text-left  px-0" no-caret text="SBN Ritel & SBSN Ritel">
                      <b-dropdown-item :to="{ path: '/sbn' }">Daftar SBN Ritel & SBSN Ritel</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/sbn/compare' }">Perbandingan SBN Ritel & SBSN Ritel</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item> -->
                  <b-dropdown-item>
                    <b-dropdown id="modalChild2" ref="test" class="nav-link navDropdownItem" size="md" left
                      variant="link" toggle-class="text-decoration-none text-left  px-0" no-caret text="Obligasi">
                      <b-dropdown-item @click="notLoginHandler">List Obligasi</b-dropdown-item>
                      <b-dropdown-item @click="notLoginHandler">Perbandingan Obligasi</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="py-1 nav-link navDropdownItem" size="md" right variant="link"
                  toggle-class="text-decoration-none text-left  px-0" no-caret text="Education">
                  <b-dropdown-item :href="`${frontendLink}/articles`">Artikel</b-dropdown-item>
                  <!-- <b-dropdown-item :to="{ path: '#' }"
                    >Event Schedule</b-dropdown-item
                  > -->
                </b-dropdown>
              </b-navbar-nav>
            </b-navbar-nav>

            <b-button size="sm" :to="{ path: '/' }" class="
                btn-tertiary
                py-2
                px-4
                d-none d-lg-inline-block d-md-inline-block
              ">Masuk</b-button>
          </b-collapse>
        </template>

        <template v-else>
          <b-collapse id="nav-collapse" is-nav class="p-0">
            <b-navbar-nav class="ml-auto">
              <b-navbar-nav>
                <b-dropdown id="modalRoot" class="py-1 nav-link navDropdownItem" size="md" right variant="link"
                  toggle-class="text-decoration-none text-left  px-0" no-caret text="Produk">
                  <!-- <b-dropdown-item :to="{ path: '/mi' }">Manajer Investasi</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/reksadana' }">List Reksadana</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/reksadana/compare' }">Bandingkan
                    Reksadana</b-dropdown-item>
                  <b-dropdown-item :to="{ path: '/mi/compare' }">Bandingkan Manajer
                    Investasi</b-dropdown-item> -->
                  <!-- Reksadana -->
                  <b-dropdown-item>
                    <b-dropdown id="modalChild" ref="test" class="nav-link navDropdownItem" size="md" left
                      variant="link" toggle-class="text-decoration-none text-left  px-0" no-caret text="Reksadana">
                      <b-dropdown-item :to="{ path: '/mi' }">Manajer Investasi</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/reksadana' }">List Reksadana</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/reksadana/compare' }">Bandingkan
                        Reksadana</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/mi/compare' }">Bandingkan Manajer
                        Investasi</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item>
                  <!-- SBN -->
                  <!-- <b-dropdown-item>
                    <b-dropdown id="modalChild3" ref="test" class="nav-link navDropdownItem" size="md" left variant="link"
                      toggle-class="text-decoration-none text-left  px-0" no-caret text="SBN Ritel & SBSN Ritel">
                      <b-dropdown-item :to="{ path: '/sbn' }">Daftar SBN Ritel & SBSN Ritel</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/sbn/compare' }">Perbandingan SBN Ritel & SBSN Ritel</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item> -->
                  <!-- Obligasi -->
                  <b-dropdown-item>
                    <b-dropdown id="modalChild2" ref="test" class="nav-link navDropdownItem" size="md" left
                      variant="link" toggle-class="text-decoration-none text-left  px-0" no-caret text="Obligasi">
                      <b-dropdown-item :to="{ path: '/obligasi' }">List Obligasi</b-dropdown-item>
                      <b-dropdown-item :to="{ path: '/obligasi/compare' }">Perbandingan Obligasi</b-dropdown-item>
                    </b-dropdown>
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Singel Item -->
                <!-- <b-nav-item class="navItem" :to="{ path: '/portfolio' }" exact
                  active-class="nav-active">Portfolio</b-nav-item> -->

                <!-- Multiple Item -->
                <b-dropdown class="py-1 nav-link navDropdownItem" size="md" right variant="link"
                  toggle-class="text-decoration-none text-left  px-0" no-caret text="Portfolio">
                  <b-dropdown-item :to="{ path: `/portfolio/semua` }">Semua</b-dropdown-item>
                  <b-dropdown-item :to="{ path: `/portfolio/reksadana` }">Reksadana</b-dropdown-item>
                  <b-dropdown-item :to="{ path: `/portfolio/obligasi` }">Obligasi</b-dropdown-item>
                  <!-- <b-dropdown-item :to="{ path: `/portfolio/sbn` }">SBN Ritel & SBSN Ritel</b-dropdown-item> -->
                </b-dropdown>
                <b-nav-item class="navItem mx-0" :to="{ path: '/orders' }" exact
                  active-class="nav-active">Aktivitas</b-nav-item>
                <b-dropdown class="py-1 nav-link navDropdownItem" size="md" right variant="link"
                  toggle-class="text-decoration-none text-left  px-0" no-caret text="Education">
                  <b-dropdown-item :href="`${frontendLink}/articles`">Artikel</b-dropdown-item>
                  <!-- <b-dropdown-item :to="{ path: '#' }"
                    >Event Schedule</b-dropdown-item
                  > -->
                </b-dropdown>
              </b-navbar-nav>
            </b-navbar-nav>

            <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <b-icon class="mx-1 text-muted" icon="bell" width="20"></b-icon>
              </template>
              <b-dropdown-item href="#">Belum ada Notifikasi</b-dropdown-item>
            </b-dropdown>
            <div v-if="user.individual != null">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret text="Left align">
                <template #button-content>
                  <b-avatar :src="'https://ui-avatars.com/api/?background=E60012&color=fff&name=' +
                    user.individual.first_name
                    " size="2rem"></b-avatar>
                </template>
                <b-dropdown-item to="/profile">Your profile</b-dropdown-item>
                <!-- <b-dropdown-item to="/withdraw">Saldo RDN</b-dropdown-item> -->
                <b-dropdown-item to="/setting">Setting</b-dropdown-item>
                <b-dropdown-item href="#" @click.prevent="logout">Sign out</b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <b-avatar :src="'https://ui-avatars.com/api/?background=EB321E&color=fff&name=' +
                    user.institutional.company_name
                    " size="2rem"></b-avatar>
                </template>
                <b-dropdown-item to="/profile">Your profile</b-dropdown-item>
                <b-dropdown-item to="/setting">Setting</b-dropdown-item>
                <b-dropdown-item href="#" @click.prevent="logout">Sign out</b-dropdown-item>
              </b-dropdown>
            </div>
          </b-collapse>
        </template>
      </b-container>
    </b-navbar>

    <!-- Modal Message -->
    <!-- Modal Register Redirect -->
    <!-- <ModalMessage :buttons="[]" :open="isOpenModalMessage" title="Anda belum terdaftar"
      bodyText="Anda akan kami arahkan kehalaman registrasi" /> -->

    <b-modal id="modal-failed-register" v-model="isOpenModalMessage" size="md" class="modal-failed-register" centered
      hide-footer>
      <div class="d-block text-center">
        <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
        <h5 class="header-modal p-4 my-2 text-dark">
          Otentikasi diperlukan, Silahkan Masuk atau Daftar
        </h5>
        <b-link class="login-link my-2 text-primary" to="/register">Silahkan Register disini</b-link>
        <!-- <p>{{errorMessage}}</p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import ModalMessage from '../modal/message.vue';
export default {
  name: "HomeHeader",
  props: {
    msg: String,
  },
  components: {
    // ModalMessage
  },
  data() {
    return {
      frontendLink: process.env.VUE_APP_ROOT_FRONTEND,
      allowModalParentClose: false,
      allowModalParentClose2: false,
      allowModalParentClose3: false,
      isOpenModalMessage: false,
    };
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      user: "auth/user",
    }),

    isFluid() {
      return (this.$route.path === '/reksadana') || (this.$route.path === '/obligasi')
    }
  },
  methods: {
    ...mapActions({
      signout: "auth/signout",
    }),
    async logout() {
      await this.signout();
      localStorage.clear();
      window.location.href = "/";
      // this.$router.replace("/");
    },
    notLoginHandler() {
      this.isOpenModalMessage = true;
      setTimeout(() => {
        this.isOpenModalMessage = false;
        window.location.href = '/'
      }, 5000);
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      const componentId = bvEvent.componentId;
      if (componentId === "modalRoot" && (!this.allowModalParentClose && !this.allowModalParentClose2 && !this.allowModalParentClose3)) bvEvent.preventDefault();

      this.allowModalParentClose = (componentId === "modalChild");
      this.allowModalParentClose2 = (componentId === "modalChild2");
      this.allowModalParentClose3 = (componentId === "modalChild3");
    });

    this.$root.$on('bv::dropdown::show', bvEvent => {
      const componentId = bvEvent.componentId;
      this.allowModalParentClose = ((componentId === "modalChild") || (componentId === "modalChild2") || (componentId === "modalChild3")) ? false : true;
      this.allowModalParentClose2 = ((componentId === "modalChild") || (componentId === "modalChild2") || (componentId === "modalChild3")) ? false : true;
      this.allowModalParentClose3 = ((componentId === "modalChild") || (componentId === "modalChild2") || (componentId === "modalChild3")) ? false : true;
    });
  }
};
</script>

<style lang="scss" scoped>
.navDropdownItem::v-deep {
  margin-right: 14px !important;

  button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2e2e2e !important;
  }
}

.navItem {
  margin: auto;
}

.buttonDropdown {
  text-align: left;
  padding: 10px 0;
}

.containerCustom {
  max-width: 100%;
}

.cgs-navbar {
  display: flex;
  flex-direction: row;
  // padding: 15px 32px 15px 32px;
  padding: 15px 10px;
  height: 70px;
  background: white;
  box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
    0px 1px 3px rgba(107, 114, 128, 0.1);
  z-index: 99;

  .row {
    width: 100%;
  }
}

.cgs-logo {
  width: 300px; //140 before
  object-fit: contain;
}

.nav-active {
  background: url("~@/assets/img/header/active-nav.svg");
  background-repeat: no-repeat;
  background-position: top center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #111827 !important;
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
}

.navbar-dark .navbar-nav .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #2e2e2e !important;
  font-family: $inter;
  margin-right: 28px;
  padding: 8px 16px 8px 16px;
}

.dropdown-item-navbar {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #2e2e2e !important;
  font-family: $inter;
  padding: 8px 16px 8px 16px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item-navbar {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu .show {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.navbar-dark .navbar-toggler {
  color: $tertiaryColor !important;
  background-color: $tertiaryColor !important;
  border-color: $tertiaryColor !important;
}

.navbar-collapse {
  background: white;
  padding: 8px;
}

.nav-item a {
  margin-right: 14px !important;
  // width: max-content;
}

.dropdown-item:focus {
  background-color: $primaryColor !important;
}

.dropdown-item:active {
  background-color: $primaryColor !important;
}

@media only screen and (max-width: 768px) {
  .cgs-logo {
    width: 100px;
  }
}

.buttonRounded {
  border-radius: 5px !important;
}

.navDropdownItem::v-deep .dropdown-item {
  padding: 10px;

  .dropdown {
    padding: 0;
    margin-right: 0 !important;

    button {
      width: 100%;
    }
  }

  .dropdown-toggle {
    padding: 0;
  }
}

.navDropdownItem::v-deep .dropdown-item::v-deep .modalChild {
  padding: 0;
}
</style>
