<template>
  <div class="reset-password">
    <!-- <Header /> -->
    <b-container class="reset-password-container">
      <b-row class="justify-content-md-start back-nav">
        <b-link href="/setting"
          ><b-icon class="mx-2" icon="chevron-left"></b-icon
          ><span>Kembali</span></b-link
        >
      </b-row>
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1 class="text-center">Ganti Password</h1>
              <template>
                <div>
                  <form @submit.prevent="handleSubmit">
                    <h3 class="reset-password-label">Password Sekarang</h3>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showOldPass"
                          id="old_password"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="old_password"
                          required
                        />
                        <vs-input
                          v-show="showOldPass"
                          id="old_password"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="old_password"
                          required
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showOldPass = !showOldPass"
                          block
                          class="btn-show-password py-2"
                        >
                          <div v-show="!showOldPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showOldPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="error my-2" v-if="wrongCurrentPassword">
                      <p>Password sekarang tidak sesuai.</p>
                    </div>

                    <h3 class="reset-password-label">Password Baru</h3>
                    <div class="my-2">
                      <small
                        ><i
                          >*password minimal 8 karakter, mengandung minimal 1
                          huruf kapital dan 1 angka</i
                        >
                      </small>
                    </div>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showPass"
                          id="password"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                          @change="inputText"
                        />
                        <vs-input
                          v-show="showPass"
                          id="password"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                          @change="inputText"
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showPass = !showPass"
                          block
                          class="btn-show-password py-2"
                        >
                          <div v-show="!showPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="error my-2" v-if="!$v.password.minLength">
                      <p>
                        Password minimal
                        {{ $v.password.$params.minLength.min }} karakter.
                      </p>
                    </div>

                    <h3 class="reset-password-label">Konfirmasi Password</h3>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showConfirmationPass"
                          id="password_confirmation"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                          @change="inputText"
                        />
                        <vs-input
                          v-show="showConfirmationPass"
                          id="password_confirmation"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                          @change="inputText"
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showConfirmationPass = !showConfirmationPass"
                          block
                          class="btn-show-password py-2"
                        >
                          <div v-show="!showConfirmationPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showConfirmationPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>

                    <div
                      class="error my-2"
                      v-if="!$v.password_confirmation.sameAsPassword"
                    >
                      <p>Password yang Anda masukkan tidak sama.</p>
                    </div>
                    <div class="error my-2" v-if="newPasswordNotSame">
                      <p>Konfirmasi password tidak cocok.</p>
                    </div>
                    <div class="error my-2" v-if="wrongPasswordFormat">
                      <p>Format isian password tidak valid.</p>
                    </div>
                    <div class="mb-4"></div>

                    <!-- <div class="my-2" v-if="failedChangePassword">
                      <p>
                        Email dan password tidak sesuai <br />
                        Silahkan cek kembali email dan password Anda
                      </p>
                    </div> -->

                    <div class="pt-2">
                      <b-button
                        block
                        :disabled="loading"
                        type="submit"
                        @click="handleSubmit()"
                        class="btn-tertiary py-2"
                      >
                        <div v-if="loading">
                          <div class="spinner-border spinner-border-sm"></div>
                          Loading
                        </div>
                        <div v-else>Submit</div>
                      </b-button>
                    </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

      <div class="modal-reset-password">
        <b-modal
          id="modal-success-reset-password"
          v-model="successChangePassword"
          size="lg"
          class="modal-success-reset-password"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/verification-proccess.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">Ganti password sukses</h1>
          </div>
        </b-modal>
      </div>

      <div class="modal-reset-password">
        <b-modal
          id="modal-success-reset-password"
          v-model="failedChangePassword"
          size="lg"
          class="modal-success-reset-password"
          centered
          hide-footer
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Terjadi kesahan
            </h1>
            <p class="text-dark">Silahkan hubungi customer care</p>
            <b-row class="justify-content-center">
              <b-button
                href="https://api.whatsapp.com/send?phone=6287777936468"
                block
                class="mt-3 mx-2 btn-tertiary py-2"
                >Hubungi customer Care</b-button
              >
            </b-row>
          </div>
        </b-modal>
      </div>
    </b-container>
    <!-- <Footer4 /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Header from "@/components/partials/header/Header.vue";
// import Footer4 from "@/components/partials/footer/Footer4.vue";
import { sameAs, minLength } from "vuelidate/lib/validators";

import axios from "axios";

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "ChangePassword",
  metaInfo: {
    title: "Ganti Password | CGS iTrade Fund",
  },
  // components: {
  //   Header,
  //   Footer4,
  // },
  data() {
    return {
      successChangePassword: false,
      failedChangePassword: false,
      newPasswordNotSame: false,
      password: "",
      old_password: "",
      password_confirmation: "",
      wrongCurrentPassword: false,
      wrongPasswordFormat: false,
      loading: false,
      processing: false,
      showOldPass: false,
      showPass: false,
      showConfirmationPass: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
      user: "auth/user",
      check: "auth/check",
    }),
    ...mapActions({
      signout: "auth/signout",
    }),
  },
  validations: {
    password: {
      minLength: minLength(8),
    },
    password_confirmation: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    inputText() {
      this.wrongPasswordFormat = false;
    },
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("client_id", (this.user.individual ? this.user.individual : this.user.institutional).client_id);
        data.append("password", this.password);
        data.append("password_confirmation", this.password_confirmation);
        data.append("old_password", this.old_password);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(`${this.app_url}2828`, data, axiosConfig)
          .then(() => {
            this.loading = !false;
            instance.post(`${process.env.VUE_APP_ROOT_API}/logout`);
            window.setTimeout(function () {
              window.location.href = "/";
            }, 3000);
            this.successChangePassword = true;
            this.processing = false;
          })
          .catch((err) => {
            if (err.response.data.data.message == "Wrong Current Password") {
              this.processing = false;
              this.successChangePassword = false;
              this.failedChangePassword = false;
              this.newPasswordNotSame = false;
              this.wrongPasswordFormat = false;
              this.wrongCurrentPassword = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Konfirmasi password tidak cocok."
            ) {
              this.processing = false;
              this.successChangePassword = false;
              this.failedChangePassword = false;
              this.wrongCurrentPassword = false;
              this.wrongPasswordFormat = false;
              this.newPasswordNotSame = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Format isian password tidak valid."
            ) {
              this.processing = false;
              this.successChangePassword = false;
              this.failedChangePassword = false;
              this.wrongCurrentPassword = false;
              this.newPasswordNotSame = false;
              this.wrongPasswordFormat = err.response.data.data.message;
            } else if (
              err.response.data.data.message ==
              "Format isian password tidak valid.,Konfirmasi password tidak cocok."
            ) {
              this.processing = false;
              this.successChangePassword = false;
              this.failedChangePassword = false;
              this.wrongCurrentPassword = false;
              this.wrongPasswordFormat = false;
              this.newPasswordNotSame = err.response.data.data.message;
            } else if (
              err.response.data.data.message == "Isian password wajib diisi."
            ) {
              this.processing = false;
              this.successChangePassword = false;
              this.failedChangePassword = false;
              this.wrongCurrentPassword = false;
              this.newPasswordNotSame = false;
              this.wrongPasswordFormat = err.response.data.data.message;
            } else {
              this.processing = false;
              this.successChangePassword = false;
              this.wrongCurrentPassword = false;
              this.newPasswordNotSame = false;
              this.wrongPasswordFormat = false;
              this.failedChangePassword = err.response.data.data.message;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  background: #f3f4f6;
}
.reset-password-container {
  padding-top: 140px;
  padding-bottom: 140px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #4b5563;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #e53e3e;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #4f566b;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.back-nav {
  padding: 0px 240px 20px 308px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.vs-con-input-label {
  width: 100%;
}

.reset-password-label {
  padding-top: 16px;
}

.reset-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #6b7280;
  text-decoration: none;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
  .reset-password-container {
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .reset-password-container {
    padding-bottom: 60px;
  }
  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }
}
</style>