<template>
  <div class="verification">
    <!-- <Header /> -->
    <div class="verification-header">
      <b-container class="verification-header-container">
        <b-row class="justify-content-center">
          <div class="verification-header text-center">
            <div class="my-4">
              <img src="@/assets/img/icons/mail-icon.svg" />
            </div>
            <h1>Verifikasi PIN</h1>
            <p>Masukkan kode <b>PIN</b> anda</p>
          </div>
        </b-row>
      </b-container>
    </div>

    <div class="otp-field">
      <b-container class="otp-container">
        <b-form>
          <b-row class="justify-content-md-center my-4">
            <template>
              <div style="display: flex; flex-direction: row">
                <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4" v-model="otp"
                  :should-auto-focus="true" :is-input-num="true" :input-type="inputType" @on-change="handleOnChange"
                  @on-complete="handleOnComplate" />
              </div>
            </template>
          </b-row>

          <b-row class="justify-content-md-center my-4">
            <b-button @click="inputType = inputType === 'tel' ? 'password' : 'tel'" class="btn-tertiary py-2">
              {{ inputType == 'tel' ? 'Sembunyikan' : 'Tampilkan' }} PIN
            </b-button>
          </b-row>

          <!-- <div class="row justify-content-center my-5">
            <div class="error-validation" v-if="errorMessage">
              <p>{{ errorMessage }}</p>
            </div>
            <div class="col-lg-12 col-md-6 text-center">
              <b-button :disabled="disabledButton || disabledButtonWhenNotFbonds || (bond_type == 1 && !product?.is_buy) || (bond_type == 2 && !product?.is_sell)" @click="checkPin()" class="btn-tertiary py-2">
                <div v-if="loading">
                  <div class="spinner-border spinner-border-sm"></div>
                  Loading
                </div>
                <div v-else>Verifikasi Sekarang</div>
              </b-button>
            </div>
          </div> -->
        </b-form>
      </b-container>
    </div>
    <Footer />

    <!-- Modal -->
    <ModalErrorPin :open="blockModal" />
    <ModalMessage :open="!!openModalMessage" :closeHandler="closeModalMessage" title="Terjadi Kesalahan"
      :bodyText="openModalMessage || ''" />
    <ModalNotRegister :isObligasi="true" :open="isModalNotRegisterOpen" :closeHandler="closeHandlerModalNotRegister" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Footer from "@/components/partials/footer/Footer.vue";
import ModalErrorPin from "@/components/partials/modal/subscription/fbonds/errorPin.vue";
import axios from "axios";
import moment from 'moment';
import momentBussiness from 'moment-business-days'
import ModalMessage from '@/components/partials/modal/message.vue';
import ModalNotRegister from '@/components/partials/modal/subscription/notRegister.vue';

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "VerficationSubscriptionObligasi",
  metaInfo: {
    title: "Verifikasi | CGS iTrade Fund",
  },
  components: {
    Footer,
    ModalErrorPin,
    ModalMessage,
    ModalNotRegister
  },
  data() {
    return {
      otp: "",
      loading: false,
      product: null,
      bond_id: '',
      bond_type: '',
      invest_nominal: null,
      harga: null,
      attempt: 1, // Menghitung berapa kali user melakukan percobaaan
      blockModal: false,
      errorMessage: null,
      openModalMessage: false,
      inputType: 'password',
      isModalNotRegisterOpen: false,
      disabledButtonWhenNotFbonds: false,

      // Data
      accurated_interest: null,
      nominal_pokok: null,
      materai: null,
      nominal_total: null,
      biaya_penjualan: null,
      pajak: null,
      sellAllPortfolio: false,

      // Portfolios
      portfolio: [],
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
      user: 'auth/user',
      s21_client: "auth/s21_client"
    }),
    ...mapActions({
      login: "auth/login",
    }),
    disabledButton() {
      if (this.loading) return true;
      if (this.attempt > 3) return true;
      return this.otp.length < 4
    },
  },
  async mounted() {
    try {
      if (!this.user.individual?.is_fbonds) {
        this.isModalNotRegisterOpen = true;
        this.disabledButtonWhenNotFbonds = true;
      }
      const checkStatus = localStorage.getItem('block_transaction_fbonds');
      const statusId = localStorage.getItem('block_transaction_fbonds_client_id');

      // Get data
      this.accurated_interest = localStorage.getItem('accurated_interest');
      this.nominal_pokok = localStorage.getItem('nominal_pokok');
      this.materai = localStorage.getItem('materai');
      this.nominal_total = localStorage.getItem('nominal_total');
      this.biaya_penjualan = localStorage.getItem('biaya_penjualan');
      this.pajak = localStorage.getItem('pajak');
      this.sellAllPortfolio = localStorage.getItem('sellAllPortfolio');

      if (this.sellAllPortfolio) {
        this.sellAllPortfolio = JSON.parse(localStorage.getItem('sellAllPortfolio'));
      }

      if (checkStatus && statusId === this.user.individual.client_id) {
        this.blockModal = true;
      }

      this.bond_id = this.$route.params.bond_id;
      this.bond_type = parseInt(this.$route.params.bond_type);
      await this.fetchFund();
      await this.fetchPortfolioObligasi();

      this.harga = localStorage.getItem('harga');
      this.invest_nominal = localStorage.getItem('invest_nominal');
      // Cegah Jika sudah melakukan transaksi tetapi melakukan action back
      if (
        !this.invest_nominal || !this.harga || !this.accurated_interest || !this.nominal_pokok ||
        !this.materai || !this.nominal_total || !this.biaya_penjualan || !this.pajak

      ) {
        this.$router.replace(`/redeem-fbonds-review/${this.bond_id}/${this.bond_type}`);
      }

      this.openModalMessage = false;
      if (!this.product.is_sell && this.bond_type == 2) {
        this.openModalMessage = `Produk tidak dapat dijual`;
      } else if (!this.product.is_buy && this.bond_type == 1) {
        this.openModalMessage = `Produk tidak dapat dibeli`;
      }
    } catch (err) {
      this.openModalMessage = `Terjadi kesalahan didalam aplikasi. Kesalahan: ${err?.message || "Terjadi Kesalahan"}`;
      console.log(err)
    }
  },
  methods: {
    async checkPin() {
      if (!this.loading) {
        this.loading = true;
        try {
          if (!this.user.individual?.client_id || !this.otp) {
            alert("Data tidak sesuai");
            return;
          }

          this.attempt = this.attempt + 1;

          await instance({
            method: "POST",
            url: `${this.app_url}fbonds/pin`,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              client_id: this.user.individual?.client_id,
              pin: this.otp,
            }
          });

          await this.handleSubmit();
          this.errorMessage = null;
          this.openModalMessage = false;

        } catch (err) {
          const errorBody = err?.response?.data?.data?.message || err?.response?.data?.meta?.message || err?.message || "Terjadi Kesalahan saat melakukan request";
          this.errorMessage = Array.isArray(errorBody) ? errorBody.join(', ') : errorBody;
          this.openModalMessage = `Terjadi kesalahan didalam aplikasi. Kesalahan: ${err?.message || "Terjadi Kesalahan"}`;
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
    },
    // handleKeyUp(){
    //   console.log('key')
    //   if(this.otp && this.otp.length >= 4){
    //     this.checkPin();
    //   } 
    // },
    handleOnChange(value) {
      this.otp = value;
    },
    async handleSubmit() {
      const investNominal = parseFloat(this.invest_nominal);

      // Looping
      let indicator = 0;
      let index = 0;
      const volumes = [];
      while (investNominal > indicator) {
        const volume = Number.isNaN(parseFloat(this.portfolio[index].available)) ? 0 : parseFloat(this.portfolio[index].available);
        indicator += volume;
        const selisih = indicator - investNominal;
        const volumeFinal = selisih > 0 ? (volume - selisih) : volume;
        volumes.push(volumeFinal);
        index++;
      }
      // Array untuk menyimpan hasil dari makeRequestItem
      const requestCollections = [];

      // Loop melalui setiap volume dalam urutan terbalik
      for (const candidate of volumes) {
        // Panggil makeRequestItem dan tunggu hingga selesai sebelum melanjutkan
        const result = await this.makeRequestItem(candidate, volumes.length);
        requestCollections.push(result);
      }

      console.log(requestCollections)

      // Setelah semua request selesai, arahkan ke '/orders'
      this.$router.push('/orders');
    },
    async fetchFund() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}fbonds/product?bond_id=${this.bond_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }

      this.product = response.data.data[0];
    },
    async fetchPortfolioObligasi() {
      let response = await instance({
        method: "POST",
        url: `${this.app_url}fbonds/portofolio`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
        data: {
          "action": "portfolio",
          "user": "Pollux",
          "client_id": `${this.user.individual.client_id}`
        }
      });

      const data = (response?.data?.meta?.type == 1 ? response?.data?.data?.portfolio : response?.data?.data) || [];
      this.portfolio = data.filter(candidate => candidate.stock == this.$route.params.bond_id && candidate.available > 0);
    },
    closeModalMessage() {
      this.openModalMessage = false
    },
    closeHandlerModalNotRegister() {
      this.isModalNotRegisterOpen = false
    },
    handleOnComplate() {
      this.checkPin();
    },
    async makeRequestItem(investNominal, length) {
      const keys = {
        buys: ['buyerid', 'buyersales', 'buyerprice', 'buyervolume'],
        seller: ['sellerid', 'sellersales', 'sellerprice', 'sellervolume'],
      };

      const vals = [`${this.user.individual?.client_id}`, `${this.s21_client?.SalesPersonID || ''} ${this.user.individual?.middle_name || ''} ${this.user.individual?.last_name || ''}`,
      `${this.harga}`,
      `${investNominal}`
      ];

      const mapping = keys[this.bond_type == 1 ? "buys" : "seller"].reduce((prev, current, index) => {
        const newPrev = { ...prev };

        newPrev[current] = vals[index];


        return newPrev;
      }, {})

      // Parsing
      const nominalPokok = (parseFloat(investNominal / this.invest_nominal) * this.nominal_pokok).toFixed();
      const accruedInterest = (parseFloat(investNominal / this.invest_nominal) * this.accurated_interest).toFixed();
      const materai = (parseFloat(investNominal / this.invest_nominal) * this.materai).toFixed();
      const nominal_total = (parseFloat((investNominal / this.invest_nominal) * this.nominal_total)).toFixed();
      const biayaPenjualan = (parseFloat(Math.ceil((this.biaya_penjualan - this.pajak) / length)).toFixed());
      const pajak = (parseFloat(investNominal / this.invest_nominal) * this.pajak).toFixed();

      return await instance
        .post(`${this.app_url}fbonds/order-transaction`, {
          "action": "ordertransaction",
          "stock": `${this.product?.bond_id}`,
          "user": `Pollux`,
          ...mapping,
          "trade_date": moment().format('DD/MM/YYYY'),
          "settle_date": momentBussiness().businessAdd(2, 'days').format('DD/MM/YYYY'),
          "channel": "P",
          "automovement": "Y",
          "order_type": this.bond_type,
          "client_id": `${this?.user?.individual?.client_id}`,

          // Special Handle
          "nominal_pokok": nominalPokok,
          "accrued_interest": accruedInterest,
          "materai": materai,
          "nominal_total": nominal_total,
          "biaya_penjualan": biayaPenjualan,
          "pajak": pajak,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${this.bearer_token}`,
          },
        })
    }
  },
  watch: {
    attempt(value) {
      if (value > 3) {
        this.blockModal = true;
        localStorage.setItem('block_transaction_fbonds', true);
        localStorage.setItem('block_transaction_fbonds_client_id', this.user.individual.client_id);
      }
    },
  }
};
</script>

<style lang="scss" scooped>
.verification-header-container {
  padding-top: 120px;
  padding-bottom: 40px;
}

.otp-field {
  background: #f3f4f6;
  padding-top: 20px;
  padding-bottom: 40px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4b5563;
}

.error-validation p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #e53e3e !important;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

.verification-header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

.verification-header p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #374151;
  margin: 0px;
}

.otp-input {
  width: 80px;
  height: 80px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-otp {
  z-index: 99999;
}

.btn-tertiary {
  font-size: 14px;
}

.btn-resend {
  font-family: $inter;
  background: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: $secondaryColor;
}

.btn-resend:hover {
  background: none;
  color: $secondaryColor;
}

.btn-resend:focus {
  box-shadow: none !important;
}

.btn-resend:active {
  background: none !important;
  outline: none !important;
  color: $secondaryColor !important;
}

@media only screen and (max-width: 768px) {
  .otp-input {
    width: 78px !important;
    height: 78px !important;
  }

  p {
    font-size: 14px !important;
  }
}
</style>
