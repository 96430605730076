import numeral from "numeral";
import cryptojs from "crypto-js";

export function formatter(val = 0) {
  if (!val) return;
  const candidate = val.toString().split("");
  if (candidate[candidate.length - 1] === ",") return val;
  return numeral(val).format(
    candidate.find((candidate) => candidate === ".") ? "0,0.0[0000]" : "0,0"
  );
}

export function backFormatter(val = 0) {
  console.log(val)
  if (!val) return;
  return parseFloat(val.toString().replace(/,/g, ""));
}

export function backTransaformFormatter(val = 0) {
  if (!val) return;
  const roundedVal = parseFloat(val).toFixed(3);
  return roundedVal
    .toString()
    .split("")
    .map((val) => val.replace(".", ","))
    .join("");
}

export function backTransaformBeforeFormat(val) {
  return formatter(backTransaformFormatter(val));
}

export function encryptData(text) {
  let iv = cryptojs.lib.WordArray.random(16),
    key = cryptojs.enc.Base64.parse(process.env.VUE_APP_ENCRYPTION_KEY);
  let options = {
    iv: iv,
    mode: cryptojs.mode.CBC,
    padding: cryptojs.pad.Pkcs7,
  };
  let encrypted = cryptojs.AES.encrypt(text, key, options);
  encrypted = encrypted.toString();
  iv = cryptojs.enc.Base64.stringify(iv);
  let result = {
    iv: iv,
    value: encrypted,
    mac: cryptojs.HmacSHA256(iv + encrypted, key).toString(),
  };
  result = JSON.stringify(result);
  result = cryptojs.enc.Utf8.parse(result);
  return cryptojs.enc.Base64.stringify(result);
}

export function decryptData(userEncrypte) {
  try {
    var encrypted_json = JSON.parse(atob(userEncrypte));
    var decrypted = cryptojs.AES.decrypt(
      encrypted_json.value,
      cryptojs.enc.Base64.parse(process.env.VUE_APP_ENCRYPTION_KEY),
      { iv: cryptojs.enc.Base64.parse(encrypted_json.iv) }
    );
    // // Decrypt in string format
    // console.log(decrypted.toString(cryptojs.enc.Utf8));
    // // Decrypt in Array, Object format
    var userDecrypted = JSON.parse(decrypted.toString(cryptojs.enc.Utf8));
    return userDecrypted;
  } catch (error) {
    console.log(error);
  }
}
